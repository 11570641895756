import React from "react";
import { connect } from "react-redux";
import {
  loadJobDocuments,
  loadSelectedJob,
  invalidateJobsDocuments,
} from "../../../../libs/redux/actions/jobActions";
import { getStatusBadgeByKey } from "../../../../libs/helpers/jobStatusHelper";
import DocumentTable from "../../../document/table/DocumentTable";
import useJobDetails from "../../hooks/useJobDetails";
import "./JobDetails.css";

const JobDetails = (props) => {
  const { job, isLoading, docsDataProvider } = useJobDetails(props);

  return (
    job && (
      <div className="job-details">
        <div className="header">
          <div className="d-flex align-items-center">
            <h3 className="mb-0 mr-3">{job.name}</h3>
            {getStatusBadgeByKey(job.status)}
          </div>
        </div>
        <div className="content">
          <DocumentTable
            dataProvider={docsDataProvider}
            showJobName={false}
            isLoading={isLoading}
          />
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    job: state.job,
  };
};

const mapDispatchToProps = {
  loadJobDocuments,
  invalidateJobsDocuments,
  loadSelectedJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);

import React from "react";
import Badge from "../../components/badge/Badge";

export const STATUS_COMPLETE = "c";
export const STATUS_ERROR = "e";
export const STATUS_NEEDS_REVIEW = "r";
export const STATUS_PROCESSING = "i";
export const STATUS_PENDING = "p";

export const statusLabels = {
  [STATUS_COMPLETE]: "Complete",
  [STATUS_ERROR]: "Error",
  [STATUS_NEEDS_REVIEW]: "Needs Review",
  [STATUS_PROCESSING]: "Processing",
  [STATUS_PENDING]: "Pending",
};

const stateBadgeTypes = {
  [STATUS_COMPLETE]: "success",
  [STATUS_ERROR]: "danger",
  [STATUS_NEEDS_REVIEW]: "warning",
  [STATUS_PROCESSING]: "warning",
  [STATUS_PENDING]: "warning",
};

export const getStatusBadgeByKey = (key) => {
  return (
    <Badge type={getBadgeTypeByKey(key)}>{getStatusLabelByKey(key)}</Badge>
  );
};

export const getBadgeTypeByKey = (key) => {
  return stateBadgeTypes?.[key];
};

export const getStatusLabelByKey = (key) => {
  return statusLabels?.[key];
};

export const isInFaultyStatus = (status) => {
  return !statusLabels[status] || status === STATUS_ERROR;
};

export const isInOperableStatus = (status) => {
  return [STATUS_COMPLETE, STATUS_NEEDS_REVIEW].includes(status);
};

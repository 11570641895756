import { mergeNestedObjects } from "../../utils";
import { FIELD_TYPE_KEY } from "../../types";
import MedicalForm from "./MedicalForm";

class MercyMedicalForm extends MedicalForm {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const { patientName, patientDOB } = this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        patientName: {
          field: patientName,
        },
        patientDOB: {
          field: patientDOB,
        },
      },
    });
  }

  getReferentFields(): any {
    const map: any = {
      patientName: {
        needle: "Patient Name:",
        type: FIELD_TYPE_KEY,
        ratingLimit: 0.9,
      },
      patientDOB: {
        needle: "Date of Birth:",
        type: FIELD_TYPE_KEY,
      },
    };

    return super.getReferentFields(map);
  }
}

export default MercyMedicalForm;

import React from "react";
import ReactSelect from "react-select";
import { defaultStyles } from "../libs/helpers/reactSelectHelper";

const Select = ({ styles, className, ...props }) => {
  return (
    <ReactSelect
      className={`react-select-control ${className ? className : ""}`}
      styles={{ ...defaultStyles, ...styles }}
      menuPlacement="auto"
      {...props}
    />
  );
};

export default Select;

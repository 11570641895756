import Field from "../../core/Field";
import MissingField from "../../core/MissingField";
import Table from "../Table";

export default class TableList extends Table {
  maxInterDist: number;
  labelIndexes: number[];
  valueIndex: number;
  tblColumns: Field[][];
  tblRows: Field[][];
  dropColumnIndexes: number[];

  constructor(
    name: string,
    fields: Field[],
    missingFields: MissingField[] = [],
    valueIndex: number = 0,
    labelIndexes: any[] = [{ index: 0 }],
    dropColumnIndexes: number[] = []
  ) {
    super(name, fields, missingFields);

    this.labelIndexes = labelIndexes;
    this.valueIndex = valueIndex;
    this.dropColumnIndexes = dropColumnIndexes;
    this.tblColumns = this.getNormalizedColumns();
    this.tblRows = this.getNormalizedRows();
  }

  get fields(): any[] {
    const result: any = [];

    this.tblRows.forEach((row) => {
      const valueField = row[this.valueIndex];
      const column = this.tblColumns[this.valueIndex];
      const colField = column
        ? column.find((x) => x.id === valueField?.id)
        : null;

      if (!colField || !valueField) {
        return;
      }

      if (valueField.id !== colField.id) {
        return;
      }

      let label = "";

      this.labelIndexes.forEach(
        ({ index, separator = " " }: any, i: number) => {
          const rowField = row[index];
          const column = this.tblColumns[index];
          const colField = column
            ? column.find((x) => x.id === rowField?.id)
            : null;

          if (!colField || !rowField) {
            return;
          }

          if (rowField.id !== colField.id) {
            return;
          }

          label +=
            rowField.extractedValue +
            (this.labelIndexes.length - 1 > i ? separator : "");
        }
      );

      valueField.label = label;

      result.push(valueField);
    });

    return result;
  }

  private getNormalizedColumns = () => {
    const result = this.columns;

    this.dropColumnIndexes.forEach((i) => {
      result[i] = null;
    });

    return result.filter(Boolean);
  };

  private getNormalizedRows = () => {
    const result = this.rows.map((row) => {
      let columnIndexOffset = 0;

      row.forEach((field, index) => {
        const column = this.tblColumns[index + columnIndexOffset];
        const colField = column ? column.find((x) => x.id === field.id) : null;

        if (!colField) {
          row[index] = null;
          columnIndexOffset--;
        }
      });

      return row.filter(Boolean);
    });

    return result;
  };
}

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getConfirmDialogOptions } from "../../../../libs/helpers/swalHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownLong, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import {
  getStatusBadgeByKey,
  isComplete,
  isProcessing,
} from "../../../../libs/helpers/jobStatusHelper";
import { formatTimestampAsDateTime } from "../../../../libs/helpers/dateTimeHelper";
import { deleteJob } from "../../../../libs/redux/actions/jobActions";
import { getJobDocuments } from "../../../../libs/api/jobApi";
import useZipWorker from "../../../../libs/services/workers/zip/useZipWorker";
import FileSaver from "file-saver";
import Swal from "sweetalert2";
import Dropdown from "../../../../components/dropdown/Dropdown";
import "./JobCard.css";

const JobCard = ({ job, isSelected, onClick, deleteJob }) => {
  const hamburgerMenuItems = [];
  const zipWorker = useZipWorker();

  useEffect(() => {
    zipWorker.onmessage = ({ data: zipBlob }) => {
      FileSaver.saveAs(zipBlob, `${job.name}.zip`);
    };
  }, [zipWorker]);

  const handleClick = (e) => {
    if (!e?.propagate) {
      onClick(job);
    }
  };
  const handleDownloadZip = (e) => {
    e.stopPropagation();

    if (!isDownloadDisabled()) {
      getJobDocuments(job.id).then(({ documents }) =>
        zipWorker.postMessage(documents)
      );
    }
  };

  const isDownloadDisabled = () =>
    !isComplete(job.status) || job.document_count < 1;

  const handleDeleteClick = (e) => {
    e.stopPropagation();

    Swal.fire({
      ...getConfirmDialogOptions(),
      text: `Are you sure you want to delete job: ${job.name}?`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteJob(job)
          .then(() => toast.success("Job successfully deleted !"))
          .catch((error) => toast.error(error.message));
      }
    });
  };

  if (!isProcessing(job.status)) {
    hamburgerMenuItems.push({
      content: "Delete Job",
      onClick: handleDeleteClick,
    });
  }

  return (
    <div
      className={"job-card" + (isSelected ? " selected" : "")}
      onClick={handleClick}
    >
      <div className="header">
        <h6 className="m-0 mr-1">{job.name}</h6>
        {getStatusBadgeByKey(job.status)}
        <button
          className="download job-action button button-link"
          disabled={isDownloadDisabled()}
          onClick={handleDownloadZip}
        >
          <FontAwesomeIcon icon={faArrowDownLong} className="mr-1" />
          Download
        </button>
      </div>
      <div className="file-count">
        {job.document_count} Total File{job.document_count === 1 ? "" : "s"}
      </div>
      <div className="footer">
        <small className="timestamp">
          Last updated {formatTimestampAsDateTime(job.updated_at)}
        </small>
        {hamburgerMenuItems.length > 0 && (
          <Dropdown
            items={hamburgerMenuItems}
            menuStyle={{ marginRight: "-0.8rem" }}
            padding={8}
          >
            <button className="button button-clear button-small job-action">
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  deleteJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCard);

import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { setIsAuthentificated } from "./libs/redux/actions/authActions";
import { ToastContainer } from "react-toastify";
import DashboardPage from "./routes/dashboard/DashboardPage";
import JobPage from "./routes/job/JobPage";
import DocumentRepository from "./routes/document/repository/DocumentRepository";
import DocumentReview from "./routes/document/review/DocumentReview";
import PageNotFound from "./routes/PageNotFound";

function App({ auth, setIsAuthentificated }) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsAuthentificated();
    setIsInitialized(true);
  }, []);

  return (
    isInitialized && auth.isAuthentificated && (
      <>
        <Routes>
          <>
            <Route exact path="/" element={<Navigate replace to="/jobs" />} />
            <Route path="/jobs" element={<JobPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/files">
              <Route index element={<DocumentRepository />} />
              <Route path=":documentId/review" element={<DocumentReview />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </>
        </Routes>
        <ToastContainer
          theme="colored"
          hideProgressBar={true}
          autoClose={5000}
        />
      </>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {
  setIsAuthentificated,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import Field from "../core/Field";
import MissingField from "../core/MissingField";
import { isOverlapping1D } from "../utils";

const ASPECT_RATIO_A4 = 1.414;

export default class Segment {
  name: string;
  content: Field[];
  missingFields: MissingField[];
  dates: Field[];

  constructor(
    name: string = null,
    content: Field[] = [],
    missingFields: MissingField[] = [],
    dates: Field[] = []
  ) {
    this.name = name;
    this.content = content;
    this.missingFields = missingFields;
    this.dates = dates;
  }

  get fields() {
    return this.content;
  }

  getFieldsBySubstring = (
    substring: string,
    startsWith: boolean = false,
    endsWith: boolean = false,
    caseSensitive: boolean = true
  ): Field[] => {
    substring = substring.trim();
    substring = caseSensitive ? substring : substring.toLowerCase();

    return this.content.filter((field) => {
      let value = field.extractedValue.trim();

      if (!caseSensitive) {
        value = value.toLowerCase();
      }

      if (startsWith) {
        return value.startsWith(substring);
      } else if (endsWith) {
        return value.endsWith(substring);
      }

      return value.includes(substring);
    });
  };

  getFirstFieldBySubstring = (
    substring: string,
    startsWith: boolean = false,
    endsWith: boolean = false,
    caseSensitive: boolean = true
  ): Field => {
    substring = substring.trim();
    substring = caseSensitive ? substring : substring.toLowerCase();

    for (let i = 0; i < this.content.length; i++) {
      const field = this.content[i];
      let value = field.extractedValue.trim();

      if (!caseSensitive) {
        value = value.toLowerCase();
      }

      if (startsWith && !value.startsWith(substring)) {
        continue;
      }

      if (endsWith && !value.endsWith(substring)) {
        continue;
      }

      if (!value.includes(substring)) {
        continue;
      }

      return field;
    }
  };

  getFieldsByKeyword = (
    keyword: string,
    caseSensitive: boolean = false
  ): Field[] => {
    keyword = keyword.trim();
    keyword = caseSensitive ? keyword : keyword.toLowerCase();

    return this.content.filter((field) => {
      let value = field.extractedValue.trim();

      if (!caseSensitive) {
        value = value.toLowerCase();
      }

      return value === keyword;
    });
  };

  getIntersectionField = (
    xField: Field,
    yField: Field,
    maxDistance: number = Number.MAX_SAFE_INTEGER,
    aspectRatio: number = ASPECT_RATIO_A4
  ): Field | null => {
    const x = xField.boundingBox.horizontalMidpoint;
    const y = yField.boundingBox.verticalMidpoint;

    const closest: any = {
      distance: maxDistance,
      field: null,
    };

    this.content.every((field) => {
      const { top, right, bottom, left } = field.boundingBox;

      let xDistance = 0;
      let yDistance = 0;

      if (!isOverlapping1D(left, right, x, x)) {
        const closestX =
          Math.abs(left - x) < Math.abs(right - x) ? left : right;

        xDistance = Math.abs(closestX - x) * aspectRatio;
      }

      if (!isOverlapping1D(top, bottom, y, y)) {
        const closestY =
          Math.abs(top - y) < Math.abs(bottom - y) ? top : bottom;

        yDistance = Math.abs(closestY - y);
      }

      const distance = xDistance + yDistance;

      if (distance <= maxDistance && closest.distance > distance) {
        closest.distance = distance;
        closest.field = field;
      }

      return distance !== 0;
    });

    return closest.field;
  };
}

import axios from "./axios/authorizedInstance";
import { handleResponse, handleError, paramsSerializer } from "./apiUtils";

const baseUrl = "/v2/document";

export function getDocuments(params) {
  return axios
    .get(`${baseUrl}/`, { params, paramsSerializer })
    .then(handleResponse)
    .catch(handleError);
}

export function getDocument(id, params) {
  return axios
    .get(`${baseUrl}/${id}/`, { params })
    .then(handleResponse)
    .catch(handleError);
}

export function updateDocument(id, params) {
  return axios
    .patch(`${baseUrl}/${id}/`, params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteDocument(id, params) {
  return axios
    .delete(`${baseUrl}/${id}/`, params)
    .then(handleResponse)
    .catch(handleError);
}

export const FILE_TYPE_SOURCE = "source";
export const FILE_TYPE_IMAGE = "image";

export const getFilesByType = (files, type) => {
  return files.filter((file) => file.type === type);
};

export const getFirstFileByType = (files, type) => {
  return files.find((file) => file.type === type);
};

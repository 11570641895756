import React from "react";
import "./Modal.css";

const Modal = ({ children, isOpened }) => {
  return (
    <>
      <div className={`modal ${isOpened ? "show" : "fade"}`}>
        <div className="modal-dialog">
          <div className="modal-content">{isOpened && children}</div>
        </div>
      </div>
      <div className={`modal-backdrop ${isOpened ? "show" : "fade"}`}></div>
    </>
  );
};

export default Modal;

import { useState, useRef, useEffect } from "react";
import { debounce } from "../../../../libs/helpers/debounceHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { statusLabels } from "../../../../libs/helpers/documentStatusHelper";
import {
  SQL_DATE_FORMAT,
  getLastNMonth,
  getLastNDay,
  getStartOfMonth,
} from "../../../../libs/helpers/dateTimeHelper";
import useExcelWorker from "../../../../libs/services/workers/excel/useExcelWorker";

const useDocumentTable = ({ dataProvider, isLoading, showJobName = true }) => {
  const defaultPageSize = getPageSizeOptionByValue(dataProvider.pageSize);
  const isMounted = useRef(false);
  const filterRef = useRef(null);
  const loaderOverride = useRef(false);

  const [status, setStatus] = useState(null);
  const [timeFrame, setTimeFrame] = useState(null);
  const [sort, setSort] = useState(null);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const excelWorker = useExcelWorker();

  useEffect(() => {
    if (loaderOverride.current && !isLoading) {
      resetLoaderOverride();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isMounted.current) return;

    debounce(filterRef, 300, () => {
      const params = getQueryParams();
      dataProvider.setQueryParams(params);
    });
  }, [timeFrame, status]);

  useEffect(() => {
    if (!isMounted.current) return;

    debounce(filterRef, 300, () => {
      dataProvider.setSort(sort);
    });
  }, [sort]);

  useEffect(() => {
    dataProvider.setPageSize(pageSize.value);
  }, [pageSize]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const getQueryParams = () => {
    const params = {};

    if (status) params.status = status.map((item) => item.value);
    if (timeFrame) params.updated_at = timeFrame.value;
    if (sort) params.ordering = sort;

    return params;
  };

  const getTableHeaderColumns = () => {
    const headers = [
      {
        label: "File Name",
        key: "name",
      },
      {
        label: "Type",
        key: "type",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Last Updated",
        key: "updated_at",
      },
    ];

    if (showJobName) {
      headers.unshift({
        label: "Job Name",
        key: "batch__name",
      });
    }

    return headers;
  };

  const getSortIcon = (key) => {
    if (sort === key) {
      return (
        <span className="sort-indicator">
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </span>
      );
    } else if (sort === `-${key}`) {
      return (
        <span className="sort-indicator">
          <FontAwesomeIcon icon={faChevronUp} size="xs" />
        </span>
      );
    }

    return (
      <span className="sort-indicator">
        <FontAwesomeIcon icon={faChevronUp} size="xs" />
        <FontAwesomeIcon icon={faChevronDown} size="xs" />
      </span>
    );
  };

  const changeSort = (sort) => {
    setSort((prevSort) => {
      if (prevSort === sort) return `-${sort}`;
      if (prevSort === `-${sort}`) return null;

      return sort;
    });
  };

  const getColumnCount = () => (showJobName ? 7 : 6);

  const resetLoaderOverride = () => (loaderOverride.current = false);

  return {
    isLoading,
    dataProvider,
    showJobName,
    status,
    setStatus,
    timeFrame,
    setTimeFrame,
    pageSize,
    setPageSize,
    getTableHeaderColumns,
    getSortIcon,
    changeSort,
    getColumnCount,
    getStatusFilterOptions,
    getTimeFrameOptions,
    getPageSizeOptions,
    loaderOverride,
    excelWorker,
  };
};

const getStatusFilterOptions = () => {
  return Object.keys(statusLabels).map((key) => ({
    label: statusLabels[key],
    value: key,
  }));
};

const getTimeFrameOptions = () => {
  return [
    {
      label: "This month",
      value: getStartOfMonth(SQL_DATE_FORMAT),
    },
    {
      label: "Last 90 days",
      value: getLastNDay(90, SQL_DATE_FORMAT),
    },
    {
      label: "Last 6 months",
      value: getLastNMonth(6, SQL_DATE_FORMAT),
    },
  ];
};

const getPageSizeOptions = () => {
  return [
    {
      label: "5 Rows",
      value: 5,
    },
    {
      label: "10 Rows",
      value: 10,
    },
    {
      label: "25 Rows",
      value: 25,
    },
    {
      label: "50 Rows",
      value: 50,
    },
  ];
};

const getPageSizeOptionByValue = (value) => {
  return getPageSizeOptions().find((option) => option.value === value);
};

export default useDocumentTable;

import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  getStatusBadgeByKey,
  isInOperableStatus,
  STATUS_ERROR,
} from "../../../libs/helpers/documentStatusHelper";
import { useDispatch } from "react-redux";
import { formatTimestampAsDateTime } from "../../../libs/helpers/dateTimeHelper";
import { download } from "../../../libs/redux/actions/fileActions";
import {
  FILE_TYPE_SOURCE,
  getFirstFileByType,
} from "../../../libs/helpers/fileTypeHelper";
import { getFormattedFileName } from "../../../libs/helpers/baseHelper";
import { getConfirmDialogOptions } from "../../../libs/helpers/swalHelper";
import { getTypeBadgeByKey } from "../../../libs/helpers/documentTypeHelper";
import { connect } from "react-redux";
import { getDocument } from "../../../libs/api/documentApi";
import { deleteDocument } from "../../../libs/redux/actions/documentActions";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import pdfIcon from "../../../assets/icons/pdf-file.svg";
import Dropdown from "../../../components/dropdown/Dropdown";
import Swal from "sweetalert2";
import Tooltip from "../../../components/tooltip/Tooltip";

const DocumentTableRow = ({
  document,
  showJobName,
  deleteDocument,
  afterDelete,
  excelWorker,
}) => {
  const dispatch = useDispatch();
  const statusBadge = getStatusBadgeByKey(document.status);

  useEffect(() => {
    excelWorker.onmessage = ({ data: { name, binary } }) => {
      const fileName = getFormattedFileName(name, "xlsx");
      FileSaver.saveAs(binary, fileName);
    };
  }, [excelWorker]);

  const getHamburgerMenuItems = () => {
    const options = [
      {
        content: "Download PDF",
        onClick: () => handleDownloadPDF(),
      },
      {
        content: "Delete File",
        onClick: () => handleDeleteDocument(),
      },
    ];

    if (isInOperableStatus(document.status)) {
      options.unshift({
        content: "Download Excel",
        onClick: () => handleDownloadExcel(document),
      });
    }

    return options;
  };

  const handleDeleteDocument = () => {
    Swal.fire({
      ...getConfirmDialogOptions(),
      text: `Are you sure you want to delete file: ${document.name}?`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocument(document)
          .then(() => {
            toast.success("File successfully deleted !");
            if (afterDelete instanceof Function) {
              afterDelete(document);
            }
          })
          .catch((error) => toast.error(error.message));
      }
    });
  };

  const handleDownloadExcel = () => {
    getDocument(document.id).then((document) =>
      excelWorker.postMessage(document)
    );
  };

  const handleDownloadPDF = () => {
    const sourceFile = getFirstFileByType(document.files, FILE_TYPE_SOURCE);

    if (sourceFile) {
      const { id, name } = sourceFile;
      const fileName = getFormattedFileName(name, "pdf");

      dispatch(download(id, { name: fileName }));
    }
  };

  return (
    <tr>
      {showJobName && <td>{document?.batch?.name}</td>}
      <td>
        <div className="d-flex align-items-center">
          <img className="mr-1" src={pdfIcon} alt="Pdf Icon" />
          <div>{document.name}</div>
        </div>
      </td>
      <td>{getTypeBadgeByKey(document.type)}</td>
      <td>
        {document.status === STATUS_ERROR ? (
          <Tooltip content={document.desc}>{statusBadge}</Tooltip>
        ) : (
          statusBadge
        )}
      </td>
      <td>{formatTimestampAsDateTime(document.updated_at)}</td>
      <td>
        {isInOperableStatus(document.status) && (
          <Link to={`/files/${document.id}/review`} className="button">
            <div>Review</div>
          </Link>
        )}
      </td>
      <td className="text-center">
        <Dropdown items={getHamburgerMenuItems()} padding={8}>
          <button className="button button-clear">
            <FontAwesomeIcon icon={faEllipsis} />
          </button>
        </Dropdown>
      </td>
    </tr>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  deleteDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTableRow);

import { combineReducers } from "redux";
import auth from "./authReducer";
import jobs from "./jobsReducer";
import job from "./jobReducer";
import document from "./documentReducer";
import documents from "./documentsReducer";

const rootReducer = combineReducers({
  auth,
  jobs,
  job,
  documents,
  document,
});

export default rootReducer;

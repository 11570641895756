import React from "react";

const Tab = ({ label, id, onClick, isActive }) => {
  const handleTabClick = () => onClick(id);

  return (
    <li className={`nav-tab ${isActive ? "active" : ""}`} onClick={handleTabClick}>
      {label}
    </li>
  );
};

export default Tab;

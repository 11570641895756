import Field from "../core/Field";
import BoundingBox from "../core/BoundingBox";
import Page from "../segments/Page";
import Segment from "../segments/Segment";
import NestedSegment from "../segments/nested-segment/NestedSegment";
import TableGrid from "../segments/table-grid/TableGrid";
import TableAxis from "../segments/table-grid/TableAxis";
import MissingField from "../core/MissingField";
import KeyValueSet from "../core/KeyValueSet";
import TableList from "../segments/table-list/TableList";
import * as strSimilarity from "string-similarity";
import {
  FIELD_TYPE_BOUNDARY,
  FIELD_TYPE_KEY,
  FIELD_TYPE_PHRASE,
  FIELD_TYPE_SELECTED_ELEMENT,
  FIELD_TYPE_UNSELECTED_ELEMENT,
  SEGMENT_TYPE_KEY_VALUE_SET,
  SEGMENT_TYPE_PHRASE,
  SEGMENT_TYPE_TABLE_GRID,
  SEGMENT_TYPE_TABLE_LIST,
} from "../types";
import SelectionElement from "../core/SelectionElement";

const TOP_EDGE_KEY = "top";
const RIGHT_EDGE_KEY = "right";
const BOTTOM_EDGE_KEY = "bottom";
const LEFT_EDGE_KEY = "left";

class Document {
  id: number;
  type: string;
  issuer: string;
  pages: Page[];
  missingFields: MissingField[];
  keyValueSets: KeyValueSet[];
  selectionElements: SelectionElement[];

  constructor({
    id,
    type,
    issuer,
    content,
    missing_fields,
    key_value_sets,
    selection_elements,
  }: any) {
    this.id = id;
    this.type = type;
    this.issuer = issuer;

    this.keyValueSets = key_value_sets.map((x: any) => new KeyValueSet(x));

    this.selectionElements = selection_elements.map(
      (x: any) => new SelectionElement(x)
    );

    this.pages = content.map(({ page_no, content = [], dates = [] }: any) => {
      content = content.map(
        ({
          id,
          confidence,
          extracted_value,
          value_override,
          geometry,
        }: any) => {
          const boundingBox = new BoundingBox(geometry);
          const field = new Field(
            id,
            confidence,
            extracted_value,
            value_override,
            boundingBox,
            page_no
          );

          this.keyValueSets.forEach((kvSet: any) => {
            if (id === kvSet.key_id) {
              kvSet.key = field;
            } else if (id === kvSet.value_id) {
              kvSet.value = field;
            }
          });

          this.selectionElements.forEach((x: SelectionElement) => {
            if (x.key_id === id) x.key = field;
          });

          return field;
        }
      );

      dates = dates.map(
        ({
          id,
          confidence,
          extracted_value,
          value_override,
          geometry,
        }: any) => {
          const boundingBox = new BoundingBox(geometry);
          const field = new Field(
            id,
            confidence,
            extracted_value,
            value_override,
            boundingBox,
            page_no
          );

          return field;
        }
      );

      return new Page(page_no, content, dates);
    });

    this.missingFields = missing_fields.map(
      (mField: any) => new MissingField(mField)
    );
  }

  public getPage = (page_no: number): Page | undefined => {
    return this.pages.find((page) => page.page_no === page_no);
  };

  public getSections(config: any = {}): any[] {
    const sections = [];

    for (let sectionKey in config) {
      let segments = [];
      const section = config[sectionKey];

      for (let itemKey in section) {
        const item = section[itemKey];

        switch (item.type) {
          case SEGMENT_TYPE_PHRASE:
          case SEGMENT_TYPE_KEY_VALUE_SET:
            segments.push(this.getBaseSegment(itemKey, item));
            break;
          case SEGMENT_TYPE_TABLE_GRID:
            segments.push(this.getTableGridSegment(itemKey, item));
            break;
          case SEGMENT_TYPE_TABLE_LIST:
            segments.push(this.getTableListSegment(itemKey, item));
            break;
          default:
        }
      }

      sections.push(new NestedSegment(sectionKey, segments));
    }

    return sections;
  }

  public getExcelData(): any {
    const data = [];
    const sections = this.getSections();
    const config = this.getExcelConfig();

    for (let i in sections) {
      const content: any = [];
      const { name, fields } = sections[i];
      const {
        colOptions = {},
        cellOptions = [],
        headerRow = null,
      } = config[name];

      if (headerRow) {
        content.push(headerRow);
      }

      for (let j in fields) {
        const field = fields[j];
        content.push([
          {
            v: field.label,
            s: cellOptions[0],
          },
          {
            v: field.value,
            s: cellOptions[1],
          },
        ]);
      }

      data.push({
        name,
        data: [
          {
            origin: {
              col: "A",
              row: 1,
            },
            content,
          },
        ],
        colOptions: colOptions,
      });
    }

    return data;
  }

  public getExcelConfig(): any {
    return {};
  }

  protected getReferentFields(map: any): any {
    const mapClone = Object.assign({}, map);
    let result: any = {};
    let mapCnt: number = 0;

    Object.keys(mapClone).forEach((key) => {
      mapCnt++;
      result[key] = null;
    });

    if (mapCnt === 0) return result;

    this.pages.forEach((page) => {
      page.fields.forEach((field) => {
        Object.entries(mapClone).forEach(([key, item]) => {
          const { offset = 0, type = FIELD_TYPE_PHRASE }: any = item;

          const relRefField = this.getRelativeRefField(type, field, item);

          if (relRefField) {
            if (offset > 0) {
              mapClone[key].offset = offset - 1;
            } else {
              result[key] = relRefField;
              delete mapClone[key];
            }
          }

          if (Object.keys(mapClone).length === 0) return;
        });
      });
    });

    return result;
  }

  protected getContentWithin = ({
    tEdge = { include: true, field: null, offset: 0 },
    rEdge = { include: true, field: null, offset: 0 },
    bEdge = { include: true, field: null, offset: 0 },
    lEdge = { include: true, field: null, offset: 0 },
  }: any): Field[] => {
    if (!tEdge.field && !rEdge.field && !bEdge.field && !lEdge.field) return [];

    let content: any = [];

    const params: any = {
      left: this.getEdgeFieldCoord(
        lEdge?.field,
        lEdge?.include ? LEFT_EDGE_KEY : RIGHT_EDGE_KEY,
        lEdge?.offset
      ),
      right: this.getEdgeFieldCoord(
        rEdge?.field,
        rEdge?.include ? RIGHT_EDGE_KEY : LEFT_EDGE_KEY,
        rEdge?.offset
      ),
    };

    let pageNoStart = tEdge?.field?.page_no ?? 1;
    let pageNoEnd = bEdge?.field?.page_no ?? this.pages.length;

    for (let i = pageNoStart; i <= pageNoEnd; i++) {
      const page = this.getPage(i);

      if (i === pageNoEnd) {
        params.bottom = this.getEdgeFieldCoord(
          bEdge?.field,
          bEdge?.include ? BOTTOM_EDGE_KEY : TOP_EDGE_KEY,
          bEdge?.offset
        );
      } else {
        params.bottom = 1;
      }

      if (i === pageNoStart) {
        params.top = this.getEdgeFieldCoord(
          tEdge?.field,
          tEdge?.include ? TOP_EDGE_KEY : BOTTOM_EDGE_KEY,
          tEdge?.offset
        );
      } else {
        params.top = 0;
      }

      const boundingBox = new BoundingBox(params);

      content = [
        ...content,
        ...page.fields.filter((field) =>
          field.isWithinBoundingBox(boundingBox)
        ),
      ];
    }

    return content;
  };

  private getEdgeFieldCoord = (
    field: Field | any,
    key: string,
    offset: number = 0
  ): number => {
    const coordinate = field?.boundingBox?.[key];

    if (!coordinate) {
      return BOTTOM_EDGE_KEY === key || RIGHT_EDGE_KEY === key ? 1 : 0;
    }

    return coordinate + offset;
  };

  private getBaseSegment = (
    key: string,
    { field, label, input_type = "text" }: any
  ): Segment => {
    if (!field) {
      field =
        this.missingFields.find((x) => x.key === key) ??
        new MissingField({ key });
    }

    field.label = label;
    field.type = input_type;

    return new Segment(key, [field]);
  };

  private getTableGridSegment = (
    name: string,
    { maxInterDist, showMissingFields = true, edges = {}, axis = {} }: any
  ): TableGrid | Segment => {
    const { column = {}, row = {} } = axis;

    const colAxis = new TableAxis(column);
    const rowAxis = new TableAxis(row);
    const content = this.getContentWithin(edges);

    return new TableGrid(
      name,
      content,
      colAxis,
      rowAxis,
      showMissingFields,
      this.missingFields,
      maxInterDist
    );
  };

  private getTableListSegment = (
    name: string,
    {
      labelIndexes,
      valueIndex,
      dropColumnIndexes,
      showMissingFields = true,
      edges = {},
    }: any
  ): TableGrid | Segment => {
    const content = this.getContentWithin(edges);

    return new TableList(
      name,
      content,
      showMissingFields,
      valueIndex,
      labelIndexes,
      dropColumnIndexes
    );
  };

  private getRelativeRefField = (type: string, field: Field, item: any) => {
    const { needle, ratingLimit = 0.8 } = item;

    switch (type) {
      case FIELD_TYPE_PHRASE:
        if (this.isSimilarField(field, needle, ratingLimit)) {
          return field;
        }
        break;
      case FIELD_TYPE_KEY:
        if (this.isSimilarField(field, needle, ratingLimit)) {
          const kvSet = this.keyValueSets.find((x) => x.key_id === field.id);
          return kvSet?.value;
        }
        break;
      case FIELD_TYPE_SELECTED_ELEMENT:
        if (this.isSimilarField(field, needle, ratingLimit)) {
          const selEl = this.selectionElements.find(
            (x) => x.key_id === field.id
          );

          return selEl?.is_selected ? selEl.key : null;
        }
        break;
      case FIELD_TYPE_UNSELECTED_ELEMENT:
        if (this.isSimilarField(field, needle, ratingLimit)) {
          const selEl = this.selectionElements.find(
            (x) => x.key_id === field.id
          );

          return selEl?.is_selected ? null : selEl?.key;
        }
        break;
      case FIELD_TYPE_BOUNDARY:
        const { boundingBox } = item;
        if (field.isWithinBoundingBox(boundingBox)) {
          return field;
        }
        break;
      default:
        return field;
    }

    return null;
  };

  private isSimilarField = (
    field: Field,
    needle: string,
    ratingLimit: number = 0.8
  ) => {
    const rating = strSimilarity.compareTwoStrings(
      field.extractedValue,
      needle
    );

    return rating >= ratingLimit;
  };
}

export default Document;

import React, { useState } from "react";
import Tab from "./Tab";
import "./NavTabs.css";

const NavTabs = ({ items }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <ul className="nav-tabs mb-4">
      {items.map((label, index) => (
        <Tab
          id={index}
          key={index}
          label={label}
          isActive={activeTab === index}
          onClick={handleTabClick}
        />
      ))}
    </ul>
  );
};

export default NavTabs;

export const defaultStyles = {
  menu: (styles) => ({
    ...styles,
    left: 0,
    margin: 0,
    minWidth: "240px",
    padding: "1.6rem 0",
    border: "1px solid #CACACA",
    boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.16)",
    borderRadius: "0.2rem 0.2rem 0.8rem 0.8rem",
  }),
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      minHeight: "3.4rem",
      borderColor: isFocused ? "#0D5BC4" : styles.borderColor,
      boxShadow: "unset",
      cursor: "pointer",
    };
  },
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    ":active": {
      backgroundColor: "#F2F7F9",
    },
    backgroundColor: isFocused || isSelected ? "#F2F7F9" : "#FFFFFF",
    color: "#222222",
    cursor: "pointer",
    whiteSpace: "nowrap",
    fontSize: "1.3rem",
    fontWeight: "400",
  }),
  placeholder: (styles) => {
    return {
      ...styles,
      color: "#222222",
      fontSize: "1.3rem",
      fontWeight: "400",
      whiteSpace: "nowrap",
    };
  },
  valueContainer: (styles) => ({
    ...styles,
    color: "#222222",
    fontSize: "1.3rem",
    fontWeight: "400",
  }),
  input: (styles) => ({
    ...styles,
    height: "2.6rem",
    color: "#222222",
    fontSize: "1.3rem",
    fontWeight: "400",
  }),
  indicatorSeparator: () => {},
};

export const getDefaultMenuStyles = () => {
  return {
    menu: (styles) => ({
      ...styles,
      border: "1px solid #CACACA",
      boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.16)",
      minWidth: "unset",
    }),
    valueContainer: (styles) => ({
      ...styles,
      color: "#222222",
      fontSize: "1.4rem",
      fontWeight: "400",
    }),
  };
};

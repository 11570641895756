import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function jobsReducer(state = initialState.jobs, action) {
  switch (action.type) {
    case types.LOAD_JOBS_SUCCESS:
      const loadedJobs = action.jobs.results.map((job) => ({
        ...job,
        documents: null,
      }));

      return {
        ...state,
        ...action.jobs,
        results: [...state?.results, ...loadedJobs],
      };
    case types.LOAD_JOB_DOCUMENTS_SUCCESS:
      return {
        ...state,
        results: state.results.map((job) => {
          if (job.id === action.jobId) {
            job.documents = action.documents;
          }

          return job;
        }),
      };
    case types.CREATE_JOB_SUCCESS:
      return {
        ...state,
        total_count: state.total_count + 1,
        results: [action.job, ...state.results],
      };
    case types.DELETE_JOB_SUCCESS:
      return {
        ...state,
        total_count: state.total_count - 1,
        results: state.results.filter((job) => job.id !== action.job.id),
      };
    case types.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        results: state.results.map((job) => {
          if (action.document?.batch.id !== job.id) return job;

          return {
            ...job,
            document_count: job.document_count - 1,
          };
        }),
      };
    case types.INVALIDATE_JOBS_DOCUMENTS:
      return {
        ...state,
        results: state.results.map((job) => ({ ...job, documents: null })),
      };
    case types.INVALIDATE_JOBS_STATE:
      return initialState.jobs;
    default:
      return state;
  }
}

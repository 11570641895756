import { mergeNestedObjects } from "../../utils";
import MedicalForm from "./MedicalForm";

class AmericanRetrievalMedicalForm extends MedicalForm {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const { patientName, patientDOB } = this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        patientName: {
          field: patientName,
        },
        patientDOB: {
          field: patientDOB,
        },
      },
    });
  }
}

export default AmericanRetrievalMedicalForm;

import { getItem, setItem } from "../services/localStorageService";

const LS_REVIEW_MODE_KEY = "review_mode";

export const REVIEW_MODE_DEFAULT = "default";
export const REVIEW_MODE_CONTINUOUS = "continuous";

export const getLocalReviewMode = () => getItem(LS_REVIEW_MODE_KEY);

export const setLocalReviewMode = (mode) => setItem(LS_REVIEW_MODE_KEY, mode);

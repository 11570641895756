import { useState, useEffect } from "react";
import useDataProvider from "../../../libs/hooks/useDataProvider";

const useJobDetails = ({
  job,
  loadJobDocuments,
  invalidateJobsDocuments,
  loadSelectedJob,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchJobDocuments = (params) => {
    if (!job?.id) return;

    setIsLoading(true);
    return loadJobDocuments(job?.id, params).finally(() => setIsLoading(false));
  };

  const docsDataProvider = useDataProvider({
    data: job?.documents,
    fetchCallback: fetchJobDocuments,
  });

  useEffect(() => {
    if (isJobSelected() && !job.documents) docsDataProvider.reload();
  }, [job?.id]);

  useEffect(() => {
    invalidateJobsDocuments();
  }, [
    docsDataProvider.queryParams,
    docsDataProvider.sort,
    docsDataProvider.page,
    docsDataProvider.pageSize,
  ]);

  useEffect(() => {
    if (isJobSelected()) {
      loadSelectedJob(job.id);
    }
  }, []);

  const isJobSelected = () => job?.id !== undefined;

  return {
    job,
    isLoading,
    docsDataProvider,
  };
};

export default useJobDetails;

import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../libs/redux/actions/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import authService from "../libs/services/authService";
import logoSvg from "../assets/img/makai-logo-ko.svg";
import NavbarMenu from "./navbar/NavbarMenu";
import Dropdown from "./dropdown/Dropdown";
import NotificationDropdown from "./notifications/Notifications";

const Header = ({ logout, auth }) => {
  const navigate = useNavigate();
  const { gapiAuth, user, name } = auth;

  const handleLogoutClick = () => {
    const refreshToken = authService.getLocalRefreshToken();

    logout(refreshToken)
      .then(() => {
        if (gapiAuth?.isSignedIn?.get()) {
          gapiAuth.disconnect();
        }
      })
      .finally(() => navigate("/login"));
  };

  const getProfileMenuItems = () => [
    {
      content: "Account Settings",
    },
    {
      content: "Documents & Resources",
    },
    {
      content: "Logout",
      onClick: handleLogoutClick,
    },
  ];

  return (
    <header>
      <div className="logo">
        <img src={logoSvg} alt="Makai" />
      </div>
      <NavbarMenu items={getNavItems()} />
      <div className="profile">
        <NotificationDropdown />
        <Dropdown items={getProfileMenuItems()} padding={16}>
          <button className="button button-header d-flex align-items-center ml-4">
            <FontAwesomeIcon icon={faUserCircle} size="lg" />
            <span className="mr-1 ml-1">{user || name}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </Dropdown>
      </div>
    </header>
  );
};

const getNavItems = () => {
  return [
    {
      to: "/dashboard",
      content: "Dashboard",
    },
    {
      to: "/jobs",
      content: "My Jobs",
    },
    {
      to: "/files",
      content: "File Repository",
    },
  ];
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import {
  FIELD_TYPE_KEY,
  SEGMENT_TYPE_KEY_VALUE_SET,
} from "../types";
import Document from "./Document";

class W2Form extends Document {
  getSectionConfig(): any {
    const {
      socialSecNo,
      ein,
      employerInfo,
      employeeInfo,
      wagesAndTips,
      incomeTax,
      socSecWages,
      socSecTax,
      medWagesAndTips,
      medTax,
      state,
      stateNoID,
      stateWagesAndTips,
      stateIncomeTax,
      localWagesAndTips,
      localIncomeTax,
      localityName,
    } = this.getReferentFields();

    return {
      "Wage and Tax Statement": {
        socialSecNo: {
          label: "Employee's social security number",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: socialSecNo,
        },
        ein: {
          label: "Employer identification number (EIN)",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: ein,
        },
        employerInfo: {
          label: "Employer's name, address and ZIP code",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: employerInfo,
        },
        employeeInfo: {
          label:
            "Employee's first name and initial, last name, suffix, address and ZIP code",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: employeeInfo,
        },
        wagesAndTips: {
          label: "Wages, tips, other compensation",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: wagesAndTips,
        },
        incomeTax: {
          label: "Federal income tax withheld",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: incomeTax,
        },
        socSecWages: {
          label: "Social security wages",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: socSecWages,
        },
        socSecTax: {
          label: "Social security tax withheld",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: socSecTax,
        },
        medWagesAndTips: {
          label: "Medicare wages and tips",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: medWagesAndTips,
        },
        medTax: {
          label: "Medicare tax withheld",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: medTax,
        },
        state: {
          label: "State",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: state,
        },
        stateNoID: {
          label: "Employer's state ID number",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: stateNoID,
        },
        stateWagesAndTips: {
          label: "State wages, tips, etc.",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: stateWagesAndTips,
        },
        stateIncomeTax: {
          label: "State income tax",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: stateIncomeTax,
        },
        localWagesAndTips: {
          label: "Local wages, tips, etc.",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: localWagesAndTips,
        },
        localIncomeTax: {
          label: "Local income tax",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: localIncomeTax,
        },
        localityName: {
          label: "Locality name",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: localityName,
        },
      },
    };
  }

  public getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getReferentFields(): any {
    const map: any = {
      socialSecNo: {
        needle: "Employee's social security number",
        type: FIELD_TYPE_KEY,
      },
      ein: {
        needle: "Employer identification number (EIN)",
        type: FIELD_TYPE_KEY,
      },
      employerInfo: {
        needle: "Employer's name, address and ZIP code",
        type: FIELD_TYPE_KEY,
      },
      employeeInfo: {
        needle: "Employee's address and ZIP code",
        type: FIELD_TYPE_KEY,
      },
      wagesAndTips: {
        needle: "Wages, tips, other compensation",
        type: FIELD_TYPE_KEY,
      },
      incomeTax: {
        needle: "Federal income tax withheld",
        type: FIELD_TYPE_KEY,
      },
      socSecWages: {
        needle: "Social security wages",
        type: FIELD_TYPE_KEY,
      },
      socSecTax: {
        needle: "Social security tax withheld",
        type: FIELD_TYPE_KEY,
      },
      medWagesAndTips: {
        needle: "Medicare wages and tips",
        type: FIELD_TYPE_KEY,
      },
      medTax: {
        needle: "Medicare tax withheld",
        type: FIELD_TYPE_KEY,
      },
      state: {
        needle: "State",
        type: FIELD_TYPE_KEY,
      },
      stateNoID: {
        needle: "Employer's state ID number",
        type: FIELD_TYPE_KEY,
      },
      stateWagesAndTips: {
        needle: "State wages, tips, etc.",
        type: FIELD_TYPE_KEY,
      },
      stateIncomeTax: {
        needle: "State income tax",
        type: FIELD_TYPE_KEY,
      },
      localWagesAndTips: {
        needle: "Local wages, tips, etc.",
        type: FIELD_TYPE_KEY,
      },
      localIncomeTax: {
        needle: "Local income tax",
        type: FIELD_TYPE_KEY,
      },
      localityName: {
        needle: "Locality name",
        type: FIELD_TYPE_KEY,
      },
    };

    return super.getReferentFields(map);
  }

  getExcelConfig(): any {
    return {
      "Wage and Tax Statement": {
        colOptions: [{ wch: 50 }, { wch: 50 }],
        cellOptions: [
          {
            font: { bold: true },
          },
        ],
      },
    };
  }
}

export default W2Form;

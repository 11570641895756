import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Pagination.css";

const FIRST_PAGE_NO = 1;

const Pagination = ({ currentPage, pageCount, setPage }) => {
  const handleControlClick = (page) => {
    if (!isCurrentPage(page)) setPage(page);
  };

  const handlePrevClick = () => {
    if (!isPrevDisabled()) setPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (!isNextDisabled()) setPage(currentPage + 1);
  };

  const hasMoreThanOnePage = () => pageCount > 1;

  const isPrevDisabled = () => currentPage === FIRST_PAGE_NO;

  const isNextDisabled = () => currentPage === pageCount;

  const isCurrentPage = (page) => page === currentPage;

  const renderControls = () => {
    const pages = getPreparedPages();

    return pages.map((page, index) => {
      const items = [];
      items.push(
        <li
          key={page}
          className={isCurrentPage(page) ? "current" : ""}
          onClick={() => handleControlClick(page)}
        >
          {page}
        </li>
      );

      const nextPage = pages[index + 1];
      if (nextPage && nextPage - page > 1) {
        items.push(
          <li className="separator" key={`separator-${page}`}>
            ...
          </li>
        );
      }

      return [...items];
    });
  };

  const getPreparedPages = () => {
    const pages = [FIRST_PAGE_NO, currentPage, pageCount];

    if (currentPage < pageCount) {
      pages.push(currentPage + 1);
    }

    if (currentPage > FIRST_PAGE_NO) {
      pages.push(currentPage - 1);
    } else if (currentPage + 1 < pageCount) {
      pages.push(currentPage + 2);
    }

    return [...new Set(pages.sort((a, b) => a - b))];
  };

  return (
    hasMoreThanOnePage() && (
      <ul className="pagination">
        <li
          className="prev"
          onClick={handlePrevClick}
          disabled={isPrevDisabled()}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </li>
        {renderControls()}
        <li
          className="next"
          onClick={handleNextClick}
          disabled={isNextDisabled()}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </li>
      </ul>
    )
  );
};

export default Pagination;

import {
  FIELD_TYPE_KEY,
  SEGMENT_TYPE_KEY_VALUE_SET,
} from '../types';
import Document from './Document';

class Nec1099Form extends Document {
  getSectionConfig(): any {
    const {
      payerInfo,
      payersTin,
      recipinetsTin,
      recipinetsName,
      streetAddress,
      extendedAddress,
      compensation,
    } = this.getReferentFields();

    return {
      'Nonemployee Compensation': {
        lastName: {
          label:
            "Payer's name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: payerInfo,
        },
        payersTin: {
          label: "Payer's TIN",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: payersTin,
        },
        recipinetsTin: {
          label: "Recipient's TIN",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: recipinetsTin,
        },
        recipinetsName: {
          label: "Recipient's name",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: recipinetsName,
        },
        streetAddress: {
          label: 'Street address (including apt no.)',
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: streetAddress,
        },
        extendedAddress: {
          label:
            'City or town, state or province, country, and ZIP or foreign postal code',
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: extendedAddress,
        },
        compensation: {
          label: 'Nonemployee compensation',
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: compensation,
        },
      },
    };
  }

  public getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getReferentFields(): any {
    const map: any = {
      payerInfo: {
        needle:
          "PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.",
        type: FIELD_TYPE_KEY,
      },
      payersTin: {
        needle: "PAYER'S TIN",
        type: FIELD_TYPE_KEY,
      },
      recipinetsTin: {
        needle: "RECIPIENTS'S TIN",
        type: FIELD_TYPE_KEY,
      },
      recipinetsName: {
        needle: "RECIPIENTS'S name",
        type: FIELD_TYPE_KEY,
      },
      streetAddress: {
        needle: 'Street address (including apt. no.)',
        type: FIELD_TYPE_KEY,
      },
      extendedAddress: {
        needle:
          'City or town, state or province, country, and ZIP or foreign postal code',
        type: FIELD_TYPE_KEY,
      },
      compensation: {
        needle: 'Nonemployee compensation',
        type: FIELD_TYPE_KEY,
      },
    };

    return super.getReferentFields(map);
  }

  getExcelConfig(): any {
    return {
      'Nonemployee Compensation': {
        colOptions: [{ wch: 50 }, { wch: 50 }],
        cellOptions: [
          {
            font: { bold: true },
          },
        ],
      },
    };
  }
}

export default Nec1099Form;

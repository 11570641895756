import Field from "./Field";

export default class DatesOfService {
  from_id: string;
  to_id: string;

  private _from: Field;
  private _to: Field;

  constructor({ from_id, to_id }: any) {
    this.from_id = from_id;
    this.to_id = to_id;
  }

  get from() {
    return this._from;
  }

  set from(field: Field) {
    this._from = field;
  }

  get to() {
    return this._to;
  }

  set to(field: Field) {
    this._to = field;
  }
}

import BoundingBox from "./core/BoundingBox";

export const isWithinBoundingBox = (
  object: BoundingBox,
  boundingBox: BoundingBox
): boolean => {
  return (
    object.top >= boundingBox.top &&
    object.right <= boundingBox.right &&
    object.bottom <= boundingBox.bottom &&
    object.left >= boundingBox.left
  );
};

export const isOverlapping2D = (a: BoundingBox, b: BoundingBox): boolean => {
  return (
    isOverlapping1D(a.left, a.right, b.left, b.right) &&
    isOverlapping1D(a.top, a.bottom, b.top, b.bottom)
  );
};

export const isOverlapping1D = (
  xmin1: number,
  xmax1: number,
  xmin2: number,
  xmax2: number
): boolean => xmax1 >= xmin2 && xmax2 >= xmin1;

export const mergeNestedObjects = (x: any, y: any) => {
  let result = { ...x, ...y };

  for (let i in result) {
    x[i] = x[i] ?? {};
    y[i] = y[i] ?? {};

    result[i] = { ...x[i], ...y[i] };
    
    for (let j in result[i]) {
      x[i][j] = x[i][j] ?? {};
      y[i][j] = y[i][j] ?? {};

      result[i][j] = { ...x[i][j], ...y[i][j] };
    }
  }

  return result;
};

import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function jobReducer(state = initialState.job, action) {
  switch (action.type) {
    case types.LOAD_JOB_DOCUMENTS_SUCCESS:
      if (action.jobId !== state?.id) return state;

      return { ...state, documents: action.documents };
    case types.DELETE_JOB_SUCCESS:
      return action.job.id === state?.id ? null : state;
    case types.DELETE_DOCUMENT_SUCCESS:
      return state?.id === action.document?.batch.id
        ? {
            ...state,
            documents: state.documents
              ? {
                  ...state.documents,
                  results: state.documents.results.filter(
                    (x) => x.id !== action.document.id
                  ),
                  total_count: state.documents.total_count - 1,
                }
              : state.documents,
          }
        : state;
    case types.LOAD_SELECTED_JOB_SUCCESS:
      return {
        ...action.job,
        documents: state?.documents ?? null,
      };
    case types.SET_SELECTED_JOB:
      return action.job;
    default:
      return state;
  }
}

import TableGrid from "../../segments/table-grid/TableGrid";
import TableAxis from "../../segments/table-grid/TableAxis";
import * as moment from "moment";
import Tearsheet from "./Tearsheet";
import { mergeNestedObjects } from "../../utils";
import Field from "../../core/Field";
import BoundingBox from "../../core/BoundingBox";
import { FIELD_TYPE_BOUNDARY } from "../../types";

class TourbillonTearsheet extends Tearsheet {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const {
      mpNet,
      fundExposure,
      performance,
      sector,
      marketCap,
      performaceAttribution,
      liquidityExposure,
      downMarketMonths,
      date,
    } = this.getReferentFields();

    let config: any = {};

    config["General Info"] = {
      date: {
        field: date,
      },
    };

    config["Monthly Performance (Net)"] = this.getMpnSectionConfig(
      mpNet,
      performance,
      fundExposure
    );

    config["Fund Exposure-SECTOR"] = this.getFesSectionConfig(
      sector,
      performaceAttribution,
      marketCap
    );

    config["Performance Attribution-SECTOR"] = this.getPasSectionConfig(
      sector,
      performance,
      marketCap
    );

    config["Liquidity Exposure"] = this.getLeSectionConfig(liquidityExposure);

    config["Performance"] = this.getPerfSectionConfig(
      performance,
      downMarketMonths
    );

    return mergeNestedObjects(super.getSectionConfig(), config);
  }

  getMpnSectionConfig = (tEdge: Field, rEdge: Field, bEdge: Field) => ({
    items: {
      edges: this.getMpnEdges(tEdge, rEdge, bEdge),
      axis: {
        column: { reverse: true },
        row: { prioritizeLabel: true },
      },
      showMissingFields: false,
    },
  });

  getMpnEdges = (tEdge: Field, rEdge: Field, bEdge: Field) => ({
    tEdge: { include: false, field: tEdge, offset: 0.01 },
    rEdge: { include: false, field: rEdge },
    bEdge: { include: false, field: bEdge },
  });

  getFesSectionConfig = (tEdge: Field, rEdge: Field, bEdge: Field) => ({
    items: {
      edges: this.getFesEdges(tEdge, rEdge, bEdge),
      axis: {
        row: { startsOn: 3, showLabel: false },
      },
    },
  });

  getFesEdges = (tEdge: Field, rEdge: Field, bEdge: Field) => ({
    tEdge: { include: false, field: tEdge, offset: 0.01 },
    rEdge: { include: false, field: rEdge },
    bEdge: { include: false, field: bEdge },
  });

  getPasSectionConfig = (tEdge: Field, rEdge: Field, bEdge: Field) => ({
    items: {
      edges: this.getPasEdges(tEdge, rEdge, bEdge),
      axis: {
        row: {
          startsOn: 6,
          endsOn: 7,
          showLabel: false,
        },
      },
    },
  });

  getPasEdges = (tEdge: Field, rEdge: Field, bEdge: Field) => ({
    tEdge: { include: false, field: tEdge, offset: 0.01 },
    rEdge: { include: false, field: rEdge },
    bEdge: { include: false, field: bEdge },
  });

  getLeSectionConfig = (tlEdge: Field) => {
    return {
      items: {
        edges: this.getLeEdges(tlEdge),
      },
    };
  };

  getLeEdges = (tlEdge: Field) => {
    const bEdge = new Field(
      null,
      null,
      null,
      null,
      new BoundingBox({ top: 0, right: 1, bottom: 1, left: 0 }),
      1
    );

    return {
      tEdge: { include: false, field: tlEdge },
      lEdge: { include: true, field: tlEdge, offset: -0.01 },
      bEdge: { include: true, field: bEdge },
    };
  };

  getPerfSectionConfig = (tlEdge: Field, bEdge: Field) => ({
    items: {
      edges: this.getPerfEdges(tlEdge, bEdge),
      axis: {
        row: { showLabel: false },
      },
    },
  });

  getPerfEdges = (tlEdge: Field, bEdge: Field) => ({
    tEdge: { include: false, field: tlEdge, offset: 0.04 },
    lEdge: { include: true, field: tlEdge, offset: -0.01 },
    bEdge: { include: false, field: bEdge },
  });

  getExcelData = (): any => [
    this.getMpnExcelSheet(),
    this.getSectorExposuresSheet(),
    this.getSectorPerformanceSheet(),
    this.getRiskAndLiquiditySheet(),
  ];

  getReferentFields(): any {
    const map: any = {
      mpNet: {
        needle: "Monthly Performance (Net)",
      },
      fundExposure: {
        needle: "Fund Exposure",
      },
      performance: {
        needle: "Performance",
      },
      sector: {
        needle: "SECTOR",
      },
      marketCap: {
        needle: "MARKETCAP",
      },
      performaceAttribution: {
        needle: "Performance Attribution",
      },
      liquidityExposure: {
        needle: "Liquidity Exposure",
      },
      downMarketMonths: {
        needle: "ITD Up vs. Down Market Months",
      },
      date: {
        type: FIELD_TYPE_BOUNDARY,
        boundingBox: new BoundingBox({
          top: 0,
          right: 1,
          bottom: 0.06,
          left: 0.7,
        }),
      },
    };

    return super.getReferentFields(map);
  }

  private getMpnExcelSheet = (): any => {
    const { mpNet, performance, fundExposure } = this.getReferentFields();

    const mpnNetContent = this.getContentWithin(
      this.getMpnEdges(mpNet, performance, fundExposure)
    );

    const mpnYYYYMMTable = new TableGrid(
      "YYYYMM",
      mpnNetContent,
      new TableAxis({ reverse: true }),
      new TableAxis({ prioritizeLabel: true, endsOn: 12 }),
      false
    );

    const mpnYYYYMMExcelData: any = [
      [
        { v: "Date (YYYYMM)", s: { font: { bold: true } } },
        { v: "Net Return", s: { font: { bold: true } } },
      ],
    ];

    mpnYYYYMMTable.fields.forEach((field: any) => {
      const dateTime = moment(field.label, "MMM YYYY");

      const yearMonthCell: any = dateTime.isValid
        ? dateTime.format("YYYYMM")
        : "";

      const netReturnCell = this.getFormattedNumCell(field.value);

      mpnYYYYMMExcelData.push([
        {
          v: yearMonthCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
        {
          v: netReturnCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
      ]);
    });

    const mpnYTDTable = new TableGrid(
      "YTD",
      mpnNetContent,
      new TableAxis({ reverse: true }),
      new TableAxis({ showLabel: false, startsOn: 12, endsOn: 13 }),
      false
    );

    const mpnYTDExcelData: any = [
      [
        { v: "Year", s: { font: { bold: true } } },
        { v: "Net Return (YTD)", s: { font: { bold: true } } },
      ],
    ];

    mpnYTDTable.fields.forEach((field: any) => {
      const yearCell: any = field.label;
      const ytdCell: any = this.getFormattedNumCell(field.value);
      mpnYTDExcelData.push([
        {
          v: yearCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
        {
          v: ytdCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
      ]);
    });

    return {
      name: "Monthly Performance (Net)",
      data: [
        {
          origin: {
            col: "A",
            row: 1,
          },
          content: mpnYYYYMMExcelData,
        },
        {
          origin: {
            col: "E",
            row: 1,
          },
          content: mpnYTDExcelData,
        },
      ],
      colOptions: [
        { wch: 15 },
        { wch: 12 },
        { wch: 8 },
        { wch: 8 },
        { wch: 12 },
        { wch: 15 },
      ],
    };
  };

  private getSectorExposuresSheet = (): any => {
    const { sector, performaceAttribution, marketCap } =
      this.getReferentFields();

    const fundExposureContent = this.getContentWithin(
      this.getFesEdges(sector, performaceAttribution, marketCap)
    );

    const sectorExposuresTable = new TableGrid(
      "Sector Exposures",
      fundExposureContent,
      new TableAxis(),
      new TableAxis({ startsOn: 3, showLabel: false })
    );

    const sectorExposuresExcelData: any = [
      [
        { v: "Sector", s: { font: { bold: true } } },
        {
          v: "Net",
          s: { font: { bold: true }, alignment: { horizontal: "right" } },
        },
      ],
    ];

    sectorExposuresTable.fields.forEach((field: any) => {
      const sectorCell = field.label;
      const netCell = this.getFormattedNumCell(field.value);

      sectorExposuresExcelData.push([
        {
          v: sectorCell,
          s: {},
        },
        {
          v: netCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
      ]);
    });

    return {
      name: "Sector Exposures",
      data: [
        {
          origin: {
            col: "A",
            row: 1,
          },
          content: sectorExposuresExcelData,
        },
      ],
      colOptions: [{ wch: 15 }, { wch: 10 }],
    };
  };

  private getSectorPerformanceSheet = (): any => {
    const { sector, performance, marketCap } = this.getReferentFields();

    const sectorPerfContent = this.getContentWithin(
      this.getPasEdges(sector, performance, marketCap)
    );

    const sectorExposuresTable = new TableGrid(
      "Sector Performance",
      sectorPerfContent,
      new TableAxis(),
      new TableAxis({ startsOn: 6, endsOn: 7, showLabel: false })
    );

    const sectorPerfExcelData: any = [
      [
        { v: "Sector", s: { font: { bold: true } } },
        {
          v: "Gross",
          s: { font: { bold: true }, alignment: { horizontal: "right" } },
        },
      ],
    ];

    sectorExposuresTable.fields.forEach((field: any) => {
      const sectorCell = field.label;
      const grossCell = this.getFormattedNumCell(field.value);

      sectorPerfExcelData.push([
        {
          v: sectorCell,
          s: {},
        },
        {
          v: grossCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
      ]);
    });

    return {
      name: "Sector Performance",
      data: [
        {
          origin: {
            col: "A",
            row: 1,
          },
          content: sectorPerfExcelData,
        },
      ],
      colOptions: [{ wch: 15 }, { wch: 10 }],
    };
  };

  private getRiskAndLiquiditySheet = (): any => {
    const { performance, downMarketMonths, liquidityExposure } =
      this.getReferentFields();

    const riskContent = this.getContentWithin(
      this.getPerfEdges(performance, downMarketMonths)
    );

    const liquidityContent = this.getContentWithin(
      this.getLeEdges(liquidityExposure)
    );

    const riskTable = new TableGrid(
      "Risk",
      riskContent,
      new TableAxis(),
      new TableAxis({ showLabel: false })
    );

    const riskExcelData: any = [
      [
        { v: "Risk", s: { font: { bold: true } } },
        {
          v: "ITD",
          s: { font: { bold: true }, alignment: { horizontal: "right" } },
        },
      ],
    ];

    riskTable.fields.forEach((field: any) => {
      const labelCell = field.label;
      const itdCell = field.value;

      riskExcelData.push([
        {
          v: labelCell,
          s: {},
        },
        {
          v: itdCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
      ]);
    });

    const liquidityTable = new TableGrid(
      "Liquidity",
      liquidityContent,
      new TableAxis(),
      new TableAxis()
    );

    const liquidityExcelData: any = [
      [
        { v: "Liquidity", s: { font: { bold: true } } },
        {
          v: "%",
          s: { font: { bold: true }, alignment: { horizontal: "right" } },
        },
      ],
    ];

    liquidityTable.fields.forEach((field: any) => {
      const labelCell: any = field.label;
      const percentageCell: any = field.value;
      liquidityExcelData.push([
        {
          v: labelCell,
          s: {},
        },
        {
          v: percentageCell,
          s: {
            alignment: {
              horizontal: "right",
            },
          },
        },
      ]);
    });

    return {
      name: "Risk & Liquidity",
      data: [
        {
          origin: {
            col: "A",
            row: 1,
          },
          content: riskExcelData,
        },
        {
          origin: {
            col: "A",
            row: 5,
          },
          content: liquidityExcelData,
        },
      ],
      colOptions: [{ wch: 15 }, { wch: 10 }],
    };
  };

  private getFormattedNumCell = (cell: string) => {
    if (!cell) return "";

    const number = cell.match(/\d+.?\d+%?/)?.[0] ?? "";
    const sign = cell.includes("(") ? "-" : "";

    return `${sign}${number}`;
  };
}

export default TourbillonTearsheet;

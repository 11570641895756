import Field from "./Field";

export default class KeyValueSet {
  key_id: string;
  value_id: string;

  private _key: Field;
  private _value: Field;

  constructor({ key_id, value_id }: any) {
    this.key_id = key_id;
    this.value_id = value_id;
  }

  get key() {
    return this._key;
  }

  set key(field: Field) {
    this._key = field;
  }

  get value() {
    return this._value;
  }

  set value(field: Field) {
    this._value = field;
  }
}

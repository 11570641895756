import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { createJob } from "../../../libs/redux/actions/jobActions";
import { connect } from "react-redux";
import ValidationError from "../../../components/ValidationError";
import Modal from "../../../components/modal/Modal";
import useJobModal from "../hooks/useJobModal";

const JobModal = (props) => {
  const {
    form,
    onChange,
    isSubmitting,
    isSubmitDisabled,
    submitForm,
    isModalOpened,
    closeModal,
    isInvalidField,
  } = useJobModal(props);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <Modal isOpened={isModalOpened}>
      <form onSubmit={handleOnSubmit} className="mb-0">
        <div className="modal-header">
          <h4 className="m-0">Add New Job</h4>
          <button
            onClick={closeModal}
            className="button button-clear button-small"
          >
            <FontAwesomeIcon icon={faTimes} size="xl" />
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="name">Job Name</label>
            <input
              type="text"
              name="name"
              onChange={onChange}
              className={isInvalidField("name") ? "invalid-field" : ""}
            />
            {isInvalidField("name") && (
              <ValidationError error={form.errors.name} />
            )}
          </div>
          <div className="form-group">
            <label htmlFor="file">Add File</label>
            <input
              type="file"
              accept=".zip"
              name="file"
              onChange={onChange}
              className={isInvalidField("file") ? "invalid-field" : "mb-0"}
            />
            {isInvalidField("file") && (
              <ValidationError error={form.errors.file} />
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="mb-0 button button-clear"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <input
            type="submit"
            value="Save"
            className="ml-2"
            disabled={isSubmitDisabled}
          />
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs,
  };
};

const mapDispatchToProps = {
  createJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobModal);

import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({
  label,
  checked,
  setChecked,
  disabled = false,
  inversed = false,
  ...props
}) => {
  const handleChange = (e) => setChecked(e.target.checked);

  return (
    <div className="toggle-switch-wrapper" {...props}>
      {!inversed && <small>{label}</small>}
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="slider"></span>
      </label>
      {inversed && <small>{label}</small>}
    </div>
  );
};

export default ToggleSwitch;

import { useNavigate } from "react-router-dom";

const useRouteGuard = () => {
  const navigate = useNavigate();

  const isIntegerParam = (param) => {
    return Number.isInteger(parseInt(param));
  };

  const handleNotFoundResponse = () => redirectToPageNotFound();

  const redirectToPageNotFound = () => {
    navigate("/page-not-found", {replace: true});
  };

  return {
    isIntegerParam,
    handleNotFoundResponse,
    redirectToPageNotFound,
  };
};

export default useRouteGuard;

import React from "react";
import Header from "../../components/Header";
import JobList from "./components/list/JobList";
import JobDetails from "./components/details/JobDetails";
import "./JobPage.css";

const JobPage = () => {
  return (
    <>
      <Header />
      <div className="job-page">
        <div className="left-panel">
          <JobList />
        </div>
        <div className="right-panel">
          <JobDetails />
        </div>
      </div>
    </>
  );
};

export default JobPage;

import { useMemo } from "react";

const useExcelWorker = () => {
  const worker = useMemo(
    () => new Worker(new URL("./excel.worker.js", import.meta.url)),
    []
  );

  return worker;
};

export default useExcelWorker;

import React from "react";
import { connect } from "react-redux";
import {
  loadDocuments,
  invalidateDocumentsState,
} from "../../../libs/redux/actions/documentActions";
import Header from "../../../components/Header";
import DocumentTable from "../table/DocumentTable";
import useDocumentRepository from "./useDocumentRepository";
import "./DocumentRepository.css";

const DocumentRepository = (props) => {
  const { dataProvider, isLoading } = useDocumentRepository(props);

  return (
    <>
      <Header />
      <div className="file-repository">
        <div className="header">
          <div className="title">File Repository</div>
        </div>
        <div className="content">
          <DocumentTable dataProvider={dataProvider} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    documents: state.documents,
  };
};

const mapDispatchToProps = {
  loadDocuments,
  invalidateDocumentsState,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRepository);

import { toast } from "react-toastify";
import useForm from "../../../libs/hooks/useForm";
import useValidation from "../../../libs/hooks/useValidation";

const useJobModal = ({
  setIsModalOpened,
  createJob,
  isModalOpened,
  setForceReloadJobs,
}) => {
  const { validate, required, file_extension, file_size, SIZE_UNIT_MB } =
    useValidation();
  const {
    form,
    setForm,
    resetForm,
    onChange,
    isInvalidField,
    isSubmitting,
    setIsSubmitting,
    isSubmitDisabled,
  } = useForm({
    fields: {
      name: { rules: [required] },
      desc: { rules: [required], value: "Batch created" },
      file: {
        rules: [
          required,
          { validator: file_extension, props: { allowed_extesions: ["zip"] } },
          {
            validator: file_size,
            props: { allowed_size: 20, unit: SIZE_UNIT_MB },
          },
        ],
      },
    },
    validate,
  });

  const submitForm = () => {
    if (!validate(form, setForm, true)) return;

    setIsSubmitting(true);

    const formData = new FormData();
    Object.entries(form.fields).forEach(([key, field]) => {
      formData.append(key, field.value);
    });

    createJob(formData)
      .then(() => {
        closeModal();
        setInitialStates();
        setForceReloadJobs(true);
        toast.success("Job created successfully !");
      })
      .catch((err) => {
        setForm({ ...form, errors: err?.response?.data ?? {} });
        toast.error("Job couldn't be created !");
        setIsSubmitting(false);
      });
  };

  const closeModal = () => {
    setIsModalOpened(false);
    setInitialStates();
  };

  const setInitialStates = () => {
    resetForm();
    setIsSubmitting(false);
  };

  return {
    form,
    onChange,
    isSubmitting,
    isSubmitDisabled,
    submitForm,
    isModalOpened,
    closeModal,
    isInvalidField,
  };
};

export default useJobModal;

import mem from "mem";
import axios from "./axios/guestInstance";
import axiosAuthorized from "./axios/authorizedInstance";
import { handleResponse, handleError } from "./apiUtils";
import { env } from "../../environments";

const baseUrl = "/v1/oauth";

export function token(username, password) {
  return axios
    .post(`${baseUrl}/token`, { username, password })
    .then(handleResponse)
    .catch(handleError);
}

export function google(token) {
  return axios
    .post(`${baseUrl}/google`, { token })
    .then(handleResponse)
    .catch(handleError);
}

export function revoke(refresh) {
  return axiosAuthorized
    .post(`${baseUrl}/revoke`, { refresh })
    .then(handleResponse)
    .catch(handleError);
}

async function refreshToken(refreshToken) {
  return axios
    .post(`${env.auth_url}token/`, { refreshToken })
    .then(handleResponse)
    .catch(handleError);
}

export const memoizedRefreshToken = mem(refreshToken, { maxAge: 10000 });

import BoundingBox from "../../core/BoundingBox";
import DatesOfService from "../../core/DatesOfService";
import Field from "../../core/Field";
import { isWithinBoundingBox } from "../../utils";
import {
  FIELD_TYPE_KEY,
  SEGMENT_TYPE_KEY_VALUE_SET,
  SEGMENT_TYPE_PHRASE,
} from "../../types";
import Document from "../Document";

class MedicalForm extends Document {
  datesOfService: DatesOfService;

  constructor({ dates_of_service = {}, ...props }: any) {
    super(props);

    this.datesOfService = new DatesOfService(dates_of_service);

    for (let i in this.pages) {
      const page = this.pages[i];

      for (let j in page.dates) {
        const date: Field = page.dates[j];

        if (date.id === this.datesOfService.from_id) {
          this.datesOfService.from = date;
        } else if (date.id === this.datesOfService.to_id) {
          this.datesOfService.to = date;
        }
      }
    }
  }

  getSectionConfig(): any {
    return {
      "General Info": {
        patientName: {
          label: "Patient Name",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
        },
        patientDOB: {
          label: "Patient DOB",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
          input_type: "date",
        },
        requestRecievedDate: {
          label: "Request Received Date",
          type: SEGMENT_TYPE_PHRASE,
          field: this.getRequestRecievedDate(),
          input_type: "date",
        },
      },
      "Dates of Service": {
        from: {
          label: "Start Date",
          type: SEGMENT_TYPE_PHRASE,
          field: this.datesOfService.from,
          input_type: "date",
        },
        to: {
          label: "End Date",
          type: SEGMENT_TYPE_PHRASE,
          field: this.datesOfService.to,
          input_type: "date",
        },
      },
    };
  }

  getReferentFields(map: any = {}): any {
    const config: any = {
      patientName: {
        needle: "RE:",
        type: FIELD_TYPE_KEY,
      },
      patientDOB: {
        needle: "DOB:",
        type: FIELD_TYPE_KEY,
      },
    };

    return super.getReferentFields(Object.assign(config, map));
  }

  getRequestRecievedDate = (): Field | null =>
    this.getStampDate() || this.getFaxHeaderDate() || this.getFirstHeaderDate();

  getStampDate = (): Field | null => {
    let stampDate: Field = null;

    for (let i in this.pages) {
      const page = this.pages[i];
      const fMRO = page.getFirstFieldBySubstring("MRO", true, true, true);

      if (!fMRO) continue;

      for (let j in page.dates) {
        const date = page.dates[j];

        if (date.boundingBox.top < fMRO.boundingBox.bottom) {
          continue;
        }

        if (stampDate && stampDate.boundingBox.top < date.boundingBox.top) {
          continue;
        }

        stampDate = date;
      }
    }

    return stampDate;
  };

  getFaxHeaderDate = (): Field | null => {
    const faxHeaderBB = new BoundingBox({
      top: 0,
      right: 1,
      bottom: 0.05,
      left: 0,
    });

    for (let i in this.pages) {
      const page = this.pages[i];
      for (let j in page.dates) {
        const date = page.dates[j];

        if (isWithinBoundingBox(date.boundingBox, faxHeaderBB)) {
          return date;
        }
      }
    }

    return null;
  };

  getFirstHeaderDate = (): Field | null => {
    const headerBB = new BoundingBox({
      top: 0,
      right: 1,
      bottom: 0.225,
      left: 0,
    });

    for (let i in this.pages) {
      const page = this.pages[i];
      for (let j in page.dates) {
        const date = page.dates[j];

        if (isWithinBoundingBox(date.boundingBox, headerBB)) {
          return date;
        }
      }
    }

    return null;
  };

  getDatesOfService = () => {};

  getExcelConfig(): any {
    return {
      "General Info": {
        colOptions: [{ wch: 30 }, { wch: 50 }],
        cellOptions: [
          {
            font: { bold: true },
          },
        ],
      },
      "Dates of Service": {
        colOptions: [{ wch: 30 }, { wch: 30 }],
        cellOptions: [
          {
            font: { bold: true },
          },
        ],
      },
    };
  }
}

export default MedicalForm;

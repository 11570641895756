import { mergeNestedObjects } from "../../utils";
import { FIELD_TYPE_KEY } from "../../types";
import Invoice from "./Invoice";

class DocuSignInvoice extends Invoice {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const {
      issuer,
      issueDate,
      dueDate,
      invoiceNumber,
      billTo,
      subscription,
      subtotalKey,
      subtotalPhrase,
      tax,
      total,
    } = this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        issuer: {
          field: issuer,
        },
        issueDate: {
          field: issueDate,
        },
        dueDate: {
          field: dueDate,
        },
        number: {
          field: invoiceNumber,
        },
        customer: {
          field: billTo,
        },
      },
      "Itemized Details": {
        items: {
          edges: {
            tEdge: { include: false, field: subscription },
            bEdge: { include: false, field: subtotalPhrase },
            lEdge: { include: false, field: subscription },
          },
          labelIndexes: [{ index: 0, separator: " / " }, { index: 1 }],
          valueIndex: 4,
        },
      },
      "Amount Due": {
        subtotal: {
          field: subtotalKey,
        },
        tax: {
          field: tax,
        },
        total: {
          field: total,
        },
      },
    });
  }

  getReferentFields(): any {
    const map: any = {
      issuer: {
        needle: "DocuSign",
      },
      issueDate: {
        needle: "Invoice Date:",
        type: FIELD_TYPE_KEY,
      },
      dueDate: {
        needle: "Due Date:",
        type: FIELD_TYPE_KEY,
      },
      invoiceNumber: {
        needle: "Invoice #:",
        type: FIELD_TYPE_KEY,
      },
      billTo: {
        needle: "Bill To:",
        type: FIELD_TYPE_KEY,
      },
      subscription: {
        needle: "Subscription",
      },
      subtotalKey: {
        type: FIELD_TYPE_KEY,
        needle: "Subtotal:",
      },
      subtotalPhrase: {
        needle: "Subtotal:",
      },
      tax: {
        type: FIELD_TYPE_KEY,
        needle: "Tax*:",
      },
      total: {
        type: FIELD_TYPE_KEY,
        needle: "Total:",
      },
    };

    return super.getReferentFields(map);
  }
}

export default DocuSignInvoice;

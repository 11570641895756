import Field from "../core/Field";
import Segment from "./Segment";

class Page extends Segment {
  page_no: number;

  constructor(page_no: number, fields: Field[], dates: Field[]) {
    super(`Page ${page_no}`, fields, [], dates);

    this.page_no = page_no;
  }
}

export default Page;

import * as types from "./actionTypes";
import * as jobApi from "../../api/jobApi";
import * as documentApi from "../../api/documentApi";

export function loadJobsSuccess(jobs) {
  return { type: types.LOAD_JOBS_SUCCESS, jobs };
}

export function loadJobDocumentsSuccess(jobId, documents) {
  return { type: types.LOAD_JOB_DOCUMENTS_SUCCESS, jobId, documents };
}

export function loadSelectedJobSuccess(job) {
  return { type: types.LOAD_SELECTED_JOB_SUCCESS, job };
}

export function createJobSuccess(job) {
  return { type: types.CREATE_JOB_SUCCESS, job };
}

export function deleteJobSuccess(job) {
  return { type: types.DELETE_JOB_SUCCESS, job };
}

export function invalidateJobsDocuments() {
  return { type: types.INVALIDATE_JOBS_DOCUMENTS };
}

export function invalidateJobsState() {
  return { type: types.INVALIDATE_JOBS_STATE };
}

export function setSelectedJob(job) {
  return { type: types.SET_SELECTED_JOB, job };
}

export function loadJobs(params, reflectResultsOnly = false) {
  return function (dispatch) {
    return jobApi
      .getJobs(params)
      .then((jobs) => {
        const payload = reflectResultsOnly
          ? { results: jobs.results, next: jobs.next }
          : jobs;
        dispatch(loadJobsSuccess(payload));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadJobDocuments(jobId, params) {
  params.batch_id = jobId;

  return function (dispatch) {
    return documentApi
      .getDocuments(params)
      .then((documents) => {
        dispatch(loadJobDocumentsSuccess(jobId, documents));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadSelectedJob(jobId, params) {
  return function (dispatch) {
    return jobApi
      .getJob(jobId, params)
      .then((job) => dispatch(loadSelectedJobSuccess(job)))
      .catch((error) => {
        throw error;
      });
  };
}

export function createJob(params) {
  return function () {
    return jobApi.createJob(params).catch((error) => {
      throw error;
    });
  };
}

export function deleteJob(job, params) {
  return function (dispatch) {
    return jobApi
      .deleteJob(job.id, params)
      .then(() => {
        dispatch(deleteJobSuccess(job));
      })
      .catch((error) => {
        throw error;
      });
  };
}

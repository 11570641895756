import moment from "moment";

export const DATE_TIME_FORMAT = "MM/DD/YYYY h:mmA";
export const MON_NAME_DATE_TIME_FORMAT = "MMM D, YYYY h:mma";
export const SQL_DATE_FORMAT = "YYYY-MM-DD";

const getDateTimeInstance = () => {
  return moment();
};

export const getStartOfMonth = (format = DATE_TIME_FORMAT) => {
  return getDateTimeInstance().startOf("month").format(format);
};

export const getLastNMonth = (n, format = DATE_TIME_FORMAT) => {
  return getDateTimeInstance().subtract(n, "months").format(format);
};

export const getLastNDay = (n, format = DATE_TIME_FORMAT) => {
  return getDateTimeInstance().subtract(n, "days").format(format);
};

export const formatTimestampAsDateTime = (
  timestamp,
  format = DATE_TIME_FORMAT
) => {
  const dateTime = moment(timestamp, moment.ISO_8601);

  return dateTime.isValid() ? dateTime.format(format) : "";
};

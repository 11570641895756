import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function documentsReducer(
  state = initialState.documents,
  action
) {
  switch (action.type) {
    case types.LOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ...action.documents,
      };
    case types.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        total_count: state.total_count - 1,
        results: state.results.filter((d) => d.id !== action.document.id),
      };
    case types.INVALIDATE_DOCUMENTS_STATE:
      return initialState.documents;
    default:
      return state;
  }
}

import Invoice from "./Invoice";
import { mergeNestedObjects } from "../../utils";
import { FIELD_TYPE_KEY } from "../../types";

class WebsiteBackupInvoice extends Invoice {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const { issuer, issueDate, accountName, total, item } =
      this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        issuer: {
          field: issuer,
        },
        issueDate: {
          field: issueDate,
        },
        customer: {
          field: accountName,
        },
      },
      "Itemized Details": {
        items: {
          edges: {
            tEdge: { include: false, field: item },
            bEdge: { include: false, field: total },
          },
          labelIndexes: [
            { index: 0, separator: " / " },
            { index: 1 },
            { index: 2 },
            { index: 3 },
          ],
          valueIndex: 4,
        },
      },
      "Amount Due": {
        total: {
          field: total,
        },
      },
    });
  }

  getReferentFields(): any {
    const map: any = {
      issuer: {
        needle: "WebsiteBackup",
      },
      issueDate: {
        needle: "DATE",
        type: FIELD_TYPE_KEY,
      },
      accountName: {
        needle: "COMPANY NAME",
        type: FIELD_TYPE_KEY,
      },
      total: {
        type: FIELD_TYPE_KEY,
        needle: "TOTAL",
      },
      item: {
        needle: "ITEM",
      },
    };

    return super.getReferentFields(map);
  }
}

export default WebsiteBackupInvoice;

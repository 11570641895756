import React from "react";
import { getDefaultMenuStyles } from "../../../libs/helpers/reactSelectHelper";
import DocumentTableRow from "./DocumentTableRow";
import CheckBoxSelect from "../../../components/checkbox-select/CheckBoxSelect";
import Select from "../../../components/Select";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/pagination/Pagination";
import useDocumentTable from "./hooks/useDocumentTable";
import "./DocumentTable.css";

const DocumentTable = (props) => {
  const {
    isLoading,
    dataProvider,
    showJobName,
    status,
    setStatus,
    timeFrame,
    setTimeFrame,
    pageSize,
    setPageSize,
    getTableHeaderColumns,
    getSortIcon,
    changeSort,
    getColumnCount,
    getStatusFilterOptions,
    getTimeFrameOptions,
    getPageSizeOptions,
    loaderOverride,
    excelWorker,
  } = useDocumentTable(props);

  const handleAfterDelete = () => {
    loaderOverride.current = true;
    dataProvider.afterDelete();
  };

  const shouldShowLoader = () => isLoading && !loaderOverride.current;

  return (
    <div className="document-table">
      <div className="d-flex align-items-center filters">
        <div className="d-flex align-items-center">
          <CheckBoxSelect
            className="mr-1 status-filter"
            options={getStatusFilterOptions()}
            value={status}
            onChange={setStatus}
            placeholder={`Status ${
              status?.length > 0 ? ` (${status.length})` : ""
            }`}
          />
          <Select
            className="timeframe-filter"
            placeholder="Timeframe"
            options={getTimeFrameOptions()}
            value={timeFrame}
            onChange={setTimeFrame}
          />
        </div>
        <div className="ml-auto">
          <strong className="mx-4">
            {`${dataProvider.totalCount} `}
            {dataProvider.totalCount === 1 ? "File" : "Files"}
          </strong>
        </div>
        <Select
          options={getPageSizeOptions()}
          value={pageSize}
          onChange={setPageSize}
          styles={getDefaultMenuStyles()}
          menuPlacement="auto"
          isSearchable={false}
          className="table-size"
        />
      </div>
      <table className="m-0">
        <thead>
          <tr>
            {getTableHeaderColumns().map((column) => {
              return (
                <th key={column.key} onClick={() => changeSort(column.key)}>
                  <div className="d-flex align-items-center">
                    <span className="mr-1">{column.label}</span>
                    {getSortIcon(column.key)}
                  </div>
                </th>
              );
            })}
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {shouldShowLoader() ? (
            <tr>
              <td
                colSpan={getColumnCount()}
                className="p-5 text-center border-0"
              >
                <Loader />
              </td>
            </tr>
          ) : dataProvider.totalCount > 0 ? (
            dataProvider
              .getRecords()
              .map((document) => (
                <DocumentTableRow
                  key={document.id}
                  document={document}
                  showJobName={showJobName}
                  afterDelete={handleAfterDelete}
                  excelWorker={excelWorker}
                />
              ))
          ) : (
            <tr>
              <td colSpan={getColumnCount()}>No files found.</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={getColumnCount()} className="text-right">
              <Pagination
                currentPage={dataProvider.page}
                setPage={dataProvider.setPage}
                pageCount={dataProvider.pageCount}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default DocumentTable;

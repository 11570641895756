import BoundingBox from "./BoundingBox";
import { isOverlapping2D, isWithinBoundingBox } from "../utils";

export default class Field {
  id: string;
  confidence: number;
  boundingBox: BoundingBox;
  extractedValue: string;
  valueOverride: string = null;
  page_no: number;

  private _label: string;
  private _type: string = "text";

  constructor(
    id: string,
    confidence: number,
    extractedValue: string,
    valueOverride: string,
    boundingBox: BoundingBox,
    page_no: number,
  ) {
    this.id = id;
    this.confidence = confidence;
    this.extractedValue = extractedValue;
    this.valueOverride = valueOverride;
    this.boundingBox = boundingBox;
    this.page_no = page_no;

    this.label = extractedValue;
  }

  get label() {
    return this._label;
  }

  set label(value) {
    this._label = value;
  }

  get value() {
    return this.valueOverride ?? this.extractedValue;
  }

  set value(value) {
    this.valueOverride = value;
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  isWithinBoundingBox = (boundingBox: BoundingBox): boolean => {
    return isWithinBoundingBox(this.boundingBox, boundingBox);
  };

  isOverlappingBoundingBox = (boundingBox: BoundingBox): boolean => {
    return isOverlapping2D(this.boundingBox, boundingBox);
  };
}

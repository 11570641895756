import Segment from "../Segment";

class NestedSegment extends Segment {
  children: Segment[];

  constructor(name: string, children: Segment[] = []) {
    super(name);
    this.children = children;
  }

  get fields(): any[] {
    return this.children.map((s: Segment) => s.fields).flat();
  }
}

export default NestedSegment;

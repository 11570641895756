import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faEllipsis,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../../components/Header";
import NavTabs from "../../components/navtabs/NavTabs";
import Badge from "../../components/badge/Badge";
import pdfIcon from "../../assets/icons/pdf-file.svg";
import dashboardChart from "../../assets/img/dashborad-chart.svg";
import "./DashboardPage.css";

const DashboardPage = ({ auth }) => {
  return (
    <>
      <Header />
      <div className="dashboard-page">
        <div className="header">
          <small className="text-gray-med">Dashboard</small>
          <div className="d-flex align-items-center">
            <h2 className="text-info mb-0">Welcome back, {auth.user || auth.name}!</h2>
            <div className="search ml-auto">
              <div className="form-icon-group icon-lg">
                <input type="text" placeholder="Search" className="mb-0" />
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <NavTabs items={getTabItems()} />
          <div className="d-flex align-items-center">
            <div className="widget-card highlighted flex-grow mr-3">
              <div className="heading">Heading</div>
              <div className="action mt-3 mb-5">
                <span className="title mr-1">Action</span>
                <FontAwesomeIcon icon={faArrowRight} size="sm" />
              </div>
              <div className="timestamp">Updated 08/30/2022 2:50am</div>
            </div>
            <div className="widget-card highlighted flex-grow mr-3">
              <div className="heading">Heading</div>
              <div className="action mt-3 mb-5">
                <span className="title mr-1">3 errors</span>
                <FontAwesomeIcon icon={faArrowRight} size="sm" />
              </div>
              <div className="timestamp">Updated 08/30/2022 2:50am</div>
            </div>
            <div className="widget-card highlighted flex-grow mr-3">
              <div className="heading">Heading</div>
              <div className="action mt-3 mb-5">
                <span className="title mr-1">Action</span>
                <FontAwesomeIcon icon={faArrowRight} size="sm" />
              </div>
              <div className="timestamp">Updated 08/30/2022 2:50am</div>
            </div>
            <div className="widget-card highlighted flex-grow">
              <div className="heading">Heading</div>
              <div className="action mt-3 mb-5">
                <span className="title mr-1">Action</span>
                <FontAwesomeIcon icon={faArrowRight} size="sm" />
              </div>
              <div className="timestamp">Updated 08/30/2022 2:50am</div>
            </div>
          </div>
        </div>
        <div className="section">
          <h5 className="subtitle mb-3">Performance metrics</h5>
          <div className="d-flex align-items-center mb-4">
            <div className="widget-card flex-grow mr-3">
              <div className="heading">Last week’s productivity</div>
              <div className="action mt-3 mb-5 d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faArrowDown}
                  size="sm"
                  className="text-danger"
                />
                <span className="title ml-1">74%</span>
              </div>
              <div className="timestamp">Lorem ipsum dolor</div>
            </div>
            <div className="widget-card flex-grow mr-3">
              <div className="heading">Another metric</div>
              <div className="action mt-3 mb-5 d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faArrowUp}
                  size="sm"
                  className="text-success"
                />
                <span className="title ml-1">67 lorem</span>
              </div>
              <div className="timestamp">Lorem ipsum dolor</div>
            </div>
            <div className="widget-card flex-grow">
              <div className="heading">Efficiency</div>
              <div className="action mt-3 mb-5">
                <span className="title">20 pages / hour</span>
              </div>
              <div className="timestamp">Lorem ipsum dolor</div>
            </div>
          </div>
          <img src={dashboardChart} alt="Performace Chart" className="w-100" />
        </div>
        <div className="section">
          <h5 className="subtitle mb-3">My Team's Queue</h5>
          <table>
            <thead>
              <tr>
                <th>Job Name</th>
                <th>File Name</th>
                <th>Status</th>
                <th>Last Updated</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Job Name</td>
                <td>
                  <div className="d-flex align-items-center">
                    <img className="mr-1" src={pdfIcon} alt="Pdf Icon" />
                    <div>California Beneficiaries</div>
                  </div>
                </td>
                <td>
                  <Badge type="warning">Complete</Badge>
                </td>
                <td>07/01/2022 11:05AM </td>
                <td>
                  <button className="button">Review</button>
                </td>
                <td className="w-0">
                  <button className="button button-clear button-icon">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Job Name</td>
                <td>
                  <div className="d-flex align-items-center">
                    <img className="mr-1" src={pdfIcon} alt="Pdf Icon" />
                    <div>California Beneficiaries</div>
                  </div>
                </td>
                <td>
                  <Badge type="warning">Complete</Badge>
                </td>
                <td>07/01/2022 11:05AM </td>
                <td>
                  <button className="button">Review</button>
                </td>
                <td className="w-0">
                  <button className="button button-clear button-icon">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Job Name</td>
                <td>
                  <div className="d-flex align-items-center">
                    <img className="mr-1" src={pdfIcon} alt="Pdf Icon" />
                    <div>California Beneficiaries</div>
                  </div>
                </td>
                <td>
                  <Badge type="warning">Complete</Badge>
                </td>
                <td>07/01/2022 11:05AM </td>
                <td>
                  <button className="button">Review</button>
                </td>
                <td className="w-0">
                  <button className="button button-clear button-icon">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Job Name</td>
                <td>
                  <div className="d-flex align-items-center">
                    <img className="mr-1" src={pdfIcon} alt="Pdf Icon" />
                    <div>California Beneficiaries</div>
                  </div>
                </td>
                <td>
                  <Badge type="warning">Complete</Badge>
                </td>
                <td>07/01/2022 11:05AM </td>
                <td>
                  <button className="button">Review</button>
                </td>
                <td className="w-0">
                  <button className="button button-clear button-icon">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Job Name</td>
                <td>
                  <div className="d-flex align-items-center">
                    <img className="mr-1" src={pdfIcon} alt="Pdf Icon" />
                    <div>California Beneficiaries</div>
                  </div>
                </td>
                <td>
                  <Badge type="warning">Complete</Badge>
                </td>
                <td>07/01/2022 11:05AM </td>
                <td>
                  <button className="button">Review</button>
                </td>
                <td className="w-0">
                  <button className="button button-clear button-icon">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const getTabItems = () => {
  return [
    "Tab Label",
    "Tab Label",
    "Another Tab",
    "Another Tab",
    "Another Tab",
    "Another Tab",
  ];
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(DashboardPage);

import { useEffect, useState } from "react";

const useDataProvider = ({
  data,
  fetchCallback,
  defaultPage = 1,
  defaultPageSize = 10,
  pageAttribute = "page",
  pageSizeAttribute = "page_size",
  sortAttribute = "ordering",
  totalCountAttribute = "total_count",
}) => {
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [queryParams, setQueryParams] = useState({});
  const [sort, setSort] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    const params = {
      ...queryParams,
      [pageAttribute]: page,
      [pageSizeAttribute]: pageSize,
    };

    if (sort) {
      params[sortAttribute] = sort;
    }

    fetchCallback(params);
  }, [page, forceRefresh]);

  useEffect(() => {
    reload();
  }, [queryParams, pageSize]);

  useEffect(() => {
    refresh();
  }, [sort]);

  const reload = () => {
    if (page !== defaultPage) {
      setPage(defaultPage);
    } else {
      refresh();
    }
  };

  const afterDelete = () => {
    const totalLoadedCount = getRecords().length;

    if (totalLoadedCount > 1) {
      return;
    } else if (page > 1) {
      setPage(page - 1);
    } else {
      reload();
    }
  };

  const refresh = () => setForceRefresh((prev) => !prev);

  const getRecords = () => {
    return data?.results ?? [];
  };

  const totalCount = data?.[totalCountAttribute] ?? 0;

  const getPageCount = () => {
    const pageCount = Math.ceil(totalCount / pageSize);

    return pageCount > 0 ? pageCount : defaultPage;
  };

  const pageCount = getPageCount();

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    queryParams,
    setQueryParams,
    sort,
    setSort,
    getRecords,
    totalCount,
    pageCount,
    afterDelete,
    refresh,
    reload,
  };
};

export default useDataProvider;

import {
  TYPE_TEARSHEET,
  TYPE_INVOICE,
  TYPE_MEDICAL_FORM,
  TYPE_I_9_FORM,
  TYPE_W_2_FORM,
  TYPE_1099_NEC_FORM,
} from "./types";
import Document from "./models/Document";
import TourbillonTearsheet from "./models/tearsheet/TourbillonTearsheet";
import DocuSignInvoice from "./models/invoice/DocuSignInvoice";
import WebsiteBackupInvoice from "./models/invoice/WebsiteBackupInvoice";
import HolmConstructionInvoice from "./models/invoice/HolmConstructionInvoice";
import StanfordPHInvoice from "./models/invoice/StanfordPHInvoice";
import PowerConsultITInvoice from "./models/invoice/PowerConsultITInvoice";
import CCLRMedicalForm from "./models/medical-form/CCLRMedicalForm";
import AmericanRetrievalMedicalForm from "./models/medical-form/AmericanRetrievalMedicalForm";
import ABIMedicalForm from "./models/medical-form/ABIMedicalForm";
import MercyMedicalForm from "./models/medical-form/MercyMedicalForm";
import I9Form from "./models/I9Form";
import W2Form from "./models/W2Form";
import Nec1099Form from "./models/Nec1099Form";

export const ISSUER_TOURBILLON = "Tourbillon";
export const ISSUER_DOCU_SIGN = "DocuSign";
export const ISSUER_STANFORD_PH = "Stanford Plumbing & Heating";
export const ISSUER_WEBSITE_BACKUP = "WebsiteBackup";
export const ISSUER_HOLM_CC = "Holm Custom Construction";
export const ISSUER_POWER_CONSULT_IT = "Power Consult IT";
export const ISSUER_CCLR = "Center City Legal Reproductions, Inc.";
export const ISSUER_AMERICAN_RETREIVAL = "American Retrieval";
export const ISSUER_ABI_DSS = "ABI Document Support Services";
export const ISSUER_MERCY_HS = "Mercy Health Services";
export const ISSUER_DEPT_OF_HOMELAND_SEC = "Department of Homeland Security";
export const ISSUER_INTERNAL_REVENUE_SERVICE =
  "W-2 Wage and Tax Statement for Internal Revenue Service";
export const ISSUER_DEPT_OF_TREASURY = "Department of Treasury";

const config: any = {
  [ISSUER_TOURBILLON]: {
    [TYPE_TEARSHEET]: TourbillonTearsheet,
  },
  [ISSUER_DOCU_SIGN]: {
    [TYPE_INVOICE]: DocuSignInvoice,
  },
  [ISSUER_STANFORD_PH]: {
    [TYPE_INVOICE]: StanfordPHInvoice,
  },
  [ISSUER_WEBSITE_BACKUP]: {
    [TYPE_INVOICE]: WebsiteBackupInvoice,
  },
  [ISSUER_HOLM_CC]: {
    [TYPE_INVOICE]: HolmConstructionInvoice,
  },
  [ISSUER_POWER_CONSULT_IT]: {
    [TYPE_INVOICE]: PowerConsultITInvoice,
  },
  [ISSUER_CCLR]: {
    [TYPE_MEDICAL_FORM]: CCLRMedicalForm,
  },
  [ISSUER_AMERICAN_RETREIVAL]: {
    [TYPE_MEDICAL_FORM]: AmericanRetrievalMedicalForm,
  },
  [ISSUER_ABI_DSS]: {
    [TYPE_MEDICAL_FORM]: ABIMedicalForm,
  },
  [ISSUER_MERCY_HS]: {
    [TYPE_MEDICAL_FORM]: MercyMedicalForm,
  },
  [ISSUER_DEPT_OF_HOMELAND_SEC]: {
    [TYPE_I_9_FORM]: I9Form,
  },
  [ISSUER_INTERNAL_REVENUE_SERVICE]: {
    [TYPE_W_2_FORM]: W2Form,
  },
  [ISSUER_DEPT_OF_TREASURY]: {
    [TYPE_1099_NEC_FORM]: Nec1099Form,
  },
};

export const getDocumentModel = (document: any) => {
  const content = JSON.parse(document.content);
  const { issuer, type } = content;

  const modelRef = config?.[issuer]?.[type] ?? Document;

  return new modelRef(content);
};

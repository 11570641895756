export const SEGMENT_TYPE_TABLE_GRID = "table_grid";
export const SEGMENT_TYPE_TABLE_LIST = "table_list";
export const SEGMENT_TYPE_KEY_VALUE_SET = "key_value_set";
export const SEGMENT_TYPE_PHRASE = "phrase";

export const FIELD_TYPE_KEY = "key";
export const FIELD_TYPE_VALUE = "value";
export const FIELD_TYPE_PHRASE = "phrase";
export const FIELD_TYPE_BOUNDARY = "boundary";
export const FIELD_TYPE_SELECTED_ELEMENT = "selected_element";
export const FIELD_TYPE_UNSELECTED_ELEMENT = "unselected_element";

export const TYPE_TEARSHEET = "tearsheet";
export const TYPE_INVOICE = "invoice";
export const TYPE_MEDICAL_FORM = "medical form";
export const TYPE_I_9_FORM = "citizenship/immigration form";
export const TYPE_W_2_FORM = "tax form";
export const TYPE_1099_NEC_FORM = "1099_nec_form";

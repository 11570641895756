import Invoice from "./Invoice";
import { mergeNestedObjects } from "../../utils";
import { FIELD_TYPE_KEY } from "../../types";

class StanfordPHInvoice extends Invoice {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const {
      issuer,
      issueDate,
      dueDate,
      invoiceNumber,
      accountName,
      description,
      subtotalKey,
      subtotalPhrase,
      tax,
      total,
    } = this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        issuer: {
          field: issuer,
        },
        issueDate: {
          field: issueDate,
        },
        dueDate: {
          field: dueDate,
        },
        number: {
          field: invoiceNumber,
        },
        customer: {
          field: accountName,
        },
      },
      "Itemized Details": {
        items: {
          edges: {
            tEdge: { include: false, field: description },
            bEdge: { include: false, field: subtotalPhrase },
          },
          valueIndex: 3,
        },
      },
      "Amount Due": {
        subtotal: {
          field: subtotalKey,
        },
        tax: {
          field: tax,
        },
        total: {
          field: total,
        },
      },
    });
  }

  getReferentFields(): any {
    const map: any = {
      issuer: {
        needle: "Stanford Plumbing & Heating",
      },
      issueDate: {
        needle: "Invoice Date:",
        type: FIELD_TYPE_KEY,
      },
      dueDate: {
        needle: "Due Date:",
        type: FIELD_TYPE_KEY,
      },
      invoiceNumber: {
        needle: "Invoice No:",
        type: FIELD_TYPE_KEY,
      },
      accountName: {
        needle: "BILL TO:",
        type: FIELD_TYPE_KEY,
      },
      subtotalKey: {
        type: FIELD_TYPE_KEY,
        needle: "SUBTOTAL",
      },
      subtotalPhrase: {
        needle: "SUBTOTAL",
      },
      tax: {
        type: FIELD_TYPE_KEY,
        needle: "TOTAL TAX",
      },
      total: {
        type: FIELD_TYPE_KEY,
        needle: "Balance Due:",
      },
      description: {
        needle: "DESCRIPTION",
      },
    };

    return super.getReferentFields(map);
  }
}

export default StanfordPHInvoice;

import Field from "./Field";

export default class SelectionElement {
  key_id: string;
  is_selected: boolean;

  private _key: Field;

  constructor({ key_id, is_selected }: any) {
    this.key_id = key_id;
    this.is_selected = is_selected;
  }

  get key() {
    return this._key;
  }

  set key(field: Field) {
    this._key = field;
  }
}

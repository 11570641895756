import { useRef } from "react";

const SIZE_UNIT_KB = "KB";
const SIZE_UNIT_MB = "MB";
const SIZE_UNIT_GB = "GB";

const SIZE_UNIT_BYTES = {
  [SIZE_UNIT_KB]: 1024,
  [SIZE_UNIT_MB]: 1024 * 1024,
  [SIZE_UNIT_GB]: 1024 * 1024 * 1024,
};

const EMAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

const useValidation = () => {
  const errors = useRef({});

  const validate = (form, setForm, isSubmitted) => {
    errors.current = {};

    isSubmitted = isSubmitted ?? form.isSubmitted;

    Object.entries(form?.fields).forEach(([key, field]) => {
      if (!field?.rules) return;

      field.rules.forEach((rule) => {
        if (rule instanceof Function) {
          rule(form, key, {});
        } else if (rule instanceof Object) {
          const { validator, props, afterSubmit = false } = rule;

          if (!afterSubmit || isSubmitted) {
            validator(form, key, props);
          }
        }
      });
    });

    const isValid = Object.keys(errors.current).length === 0;

    setForm({
      ...form,
      errors: errors.current,
      isSubmitted,
      isValid,
    });

    return isValid;
  };

  const required = (form, key, { message = "This field is required." }) => {
    const val = getValueByKey(form, key);

    if ((typeof val === "string" && val.trim()) || val) return;

    errors.current[key] = message;
  };

  const email = (form, key, { message = "Email format is invalid." }) => {
    const email = getValueByKey(form, key);

    if (!String(email).toLocaleLowerCase().match(EMAIL_REGEX)) {
      errors.current[key] = message;
    }
  };

  const compare = (
    form,
    key,
    {
      compareAttribute,
      message = `This field does not match ${compareAttribute}.`,
    }
  ) => {
    const value = getValueByKey(form, key);
    const compareValue = getValueByKey(form, compareAttribute);

    if (value !== compareValue) {
      errors.current[key] = message;
    }
  };

  const minLength = (
    form,
    key,
    { min = 0, message = `This field must have at least ${min} characters.` }
  ) => {
    const value = getValueByKey(form, key);
    const length = value.length;

    if (min > length) {
      errors.current[key] = message;
    }
  };

  const maxLength = (
    form,
    key,
    { max = 0, message = `This field exceeds max length of ${max} characters.` }
  ) => {
    const value = getValueByKey(form, key);
    const length = value.length;

    if (max < length) {
      errors.current[key] = message;
    }
  };

  const file_extension = (
    form,
    key,
    { allowed_extesions = [], message = "Invalid file format." }
  ) => {
    const file = getValueByKey(form, key);

    if (!(file instanceof File)) return;

    const extension = file.name.split(".").pop();

    if (!allowed_extesions.includes(extension)) {
      errors.current[key] = message;
    }
  };

  const file_size = (
    form,
    key,
    {
      allowed_size = 1,
      unit = SIZE_UNIT_MB,
      message = `The selected file exceeds the ${allowed_size}${unit} size limit.`,
    }
  ) => {
    const file = getValueByKey(form, key);

    if (!(file instanceof File) || !SIZE_UNIT_BYTES[unit]) return;

    const fileSize = file.size / SIZE_UNIT_BYTES[unit];

    if (allowed_size < fileSize) {
      errors.current[key] = message;
    }
  };

  const getValueByKey = (form, key) => form?.fields[key]?.value ?? null;

  return {
    validate,
    required,
    email,
    compare,
    minLength,
    maxLength,
    file_extension,
    file_size,
    SIZE_UNIT_KB,
    SIZE_UNIT_MB,
    SIZE_UNIT_GB,
  };
};

export default useValidation;

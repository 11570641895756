import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  MON_NAME_DATE_TIME_FORMAT,
  formatTimestampAsDateTime,
} from "../../../../libs/helpers/dateTimeHelper";
import { STATUS_COMPLETE } from "../../../../libs/helpers/documentStatusHelper";
import { getDocumentModel } from "../../../../libs/modules/parser";
import {
  getFilesByType,
  FILE_TYPE_IMAGE,
} from "../../../../libs/helpers/fileTypeHelper";
import { getFormattedFileName } from "../../../../libs/helpers/baseHelper";
import { env } from "../../../../environments";
import FileSaver from "file-saver";
import useRouteGuard from "../../../../libs/hooks/useRouteGuard";
import useReviewMode from "./useReviewMode";
import useExcelWorker from "../../../../libs/services/workers/excel/useExcelWorker";

const useDocumentReview = ({
  document,
  download,
  loadDocument,
  updateDocument,
  invalidateDocumentState,
}) => {
  const { handleNotFoundResponse } = useRouteGuard();
  const excelWorker = useExcelWorker();
  const {
    isContinuousReviewMode,
    setIsContinuousReviewMode,
    afterCompletedReview,
  } = useReviewMode({ document });

  const documentModel = useRef(null);
  const { documentId } = useParams();
  const [sections, setSections] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [highlightBox, setHighlightBox] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [form, setForm] = useState({});

  useEffect(() => {
    setIsLoading(true);
    loadDocument(documentId)
      .then(() => setIsLoading(false))
      .catch(handleNotFoundResponse);

    return () => {
      setSelectedField({});
      invalidateDocumentState();
    };
  }, [documentId]);

  useEffect(() => {
    if (!document) return;

    documentModel.current = getDocumentModel(document);

    setSections(documentModel.current.getSections());
  }, [document]);

  useEffect(() => {
    excelWorker.onmessage = ({ data: { name, binary } }) => {
      const fileName = getFormattedFileName(name, "xlsx");
      FileSaver.saveAs(binary, fileName);
    };
  }, [excelWorker, document]);

  useEffect(() => {
    const { page_no = null, boundingBox = null } = selectedField || {};

    if (page_no && boundingBox) {
      setHighlightBox({ pageIndex: page_no - 1, boundingBox });
    } else {
      setHighlightBox(null);
    }
  }, [selectedField]);

  const downloadExcel = () => {
    excelWorker.postMessage(document);
  };

  const saveFileDetails = (completeReview = false) => {
    const params = getFormattedFormParams();

    if (completeReview) {
      params.status = STATUS_COMPLETE;
    }

    setIsSaving(true);
    updateDocument(documentId, params)
      .then(() => {
        resetForm();
        if (!completeReview) {
          toast.success("File changes saved successfully !");
        } else {
          afterCompletedReview();
        }
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsSaving(false));
  };

  const getDocumentImages = () => {
    const files = document ? document.files : [];
    const images = getFilesByType(files, FILE_TYPE_IMAGE);

    return images.map((image) => {
      return {
        src: `${env.apiUrl}/v2/file/${image.id}/download/`,
      };
    });
  };

  const getFormattedFormParams = () => {
    const missingFields = documentModel.current.missingFields;
    const fields = form.fields || {};
    let commonModel = JSON.parse(document.content);

    const content = commonModel.content.map((page) => {
      const pageContent = page.content.map((field) => {
        if (fields[field.id]) {
          const value_override = fields[field.id];
          delete fields[field.id];

          return { ...field, value_override };
        }

        return field;
      });

      const dates = page.dates ?? [];
      const pageDates = dates.map((field) => {
        if (fields[field.id]) {
          const value_override = fields[field.id];
          delete fields[field.id];

          return { ...field, value_override };
        }

        return field;
      });

      return { ...page, content: pageContent, dates: pageDates };
    });

    if (Object.keys(fields).length > 0) {
      for (let i in fields) {
        const value = fields[i];
        const index = missingFields.map((x) => x.key).indexOf(i);

        if (index < 0) {
          missingFields.push({
            key: i,
            value,
          });
        } else {
          missingFields[index].value = value;
        }
      }
    }

    commonModel = { ...commonModel, content, missing_fields: missingFields };

    return { content: JSON.stringify(commonModel) };
  };

  const getFormattedLastUpdatedLabel = () => {
    if (!document?.updated_at) return;

    const formattedDateTime = formatTimestampAsDateTime(
      document.updated_at,
      MON_NAME_DATE_TIME_FORMAT
    );
    const updatedAtLabel = formattedDateTime ? `on ${formattedDateTime}` : "";

    return `Last updated ${updatedAtLabel}`;
  };

  const resetForm = () => setForm({});

  return {
    document,
    sections,
    setSections,
    isLoading,
    isSaving,
    setForm,
    selectedField,
    setSelectedField,
    getDocumentImages,
    getFormattedLastUpdatedLabel,
    download,
    downloadExcel,
    saveFileDetails,
    isContinuousReviewMode,
    setIsContinuousReviewMode,
    highlightBox,
  };
};

export default useDocumentReview;

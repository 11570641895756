import axios from "./axios/authorizedInstance";
import { handleResponse, handleError } from "./apiUtils";
import FileSaver from "file-saver";

const baseUrl = "/v2/file";

export function download(id, params) {
  const { name } = params;

  return axios
    .get(`${baseUrl}/${id}/download/`, {
      responseType: "blob",
    })
    .then(handleResponse)
    .then((blob) => FileSaver.saveAs(blob, name))
    .catch(handleError);
}

import Cookies from 'js-cookie';
import { getItem, setItem, removeItem } from "./localStorageService";
const LS_AUTH_KEY = "auth";

const getAuthData = () => {
 const localStorage = getItem(LS_AUTH_KEY)
 const cookies = Cookies.get('access_token_cookie')
 return localStorage || cookies;
};

const setAuthData = (auth) => setItem(LS_AUTH_KEY, auth);

const removeAuthData = () => {
  removeItem(LS_AUTH_KEY)
  Cookies.remove('access_token_cookie')
  Cookies.remove('refresh_token_cookie')
 };

const getLocalRefreshToken = () => {
  const auth = getAuthData();
  const cookies = Cookies.get('refresh_token_cookie')
  return auth?.refresh || cookies;
};

const getLocalAccessToken = () => {
  const auth = getAuthData();
  const cookies = Cookies.get('access_token_cookie')
  return auth?.access || cookies;
};

const isAuthentificated = () => {
  return getLocalAccessToken() !== undefined;
};

const authService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getAuthData,
  setAuthData,
  removeAuthData,
  isAuthentificated,
};

export default authService;

import { useMemo } from "react";

const useZipWorker = () => {
  const worker = useMemo(
    () => new Worker(new URL("./zip.worker.js", import.meta.url)),
    []
  );

  return worker;
};

export default useZipWorker;

import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  loadJobs,
  invalidateJobsState,
  setSelectedJob,
} from "../../../../libs/redux/actions/jobActions";
import CheckBoxSelect from "../../../../components/checkbox-select/CheckBoxSelect";
import Select from "../../../../components/Select";
import JobCard from "../card/JobCard";
import JobModal from "../JobModal";
import useJobList from "../../hooks/useJobList";
import Loader from "../../../../components/Loader";
import "./JobList.css";

const JobList = (props) => {
  const {
    jobs,
    results,
    isLoading,
    selectedJob,
    setSelectedJob,
    setSearch,
    status,
    setStatus,
    getStatusFilterOptions,
    sort,
    setSort,
    getSortOptions,
    resolveBottomEdgeScroll,
    isJobModalOpened,
    setIsJobModalOpened,
    setForceReloadJobs,
  } = useJobList(props);

  const handleOnJobListScroll = (e) => resolveBottomEdgeScroll(e.target);

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSelectJob = (job) => {
    if (job.id !== selectedJob?.id) setSelectedJob(job);
  };

  const handleAddJobBtnClick = () => setIsJobModalOpened(true);

  return (
    <div className="job-list">
      <div className="title d-flex align-items-center">
        <h4 className="m-0">My Jobs ({jobs.total_count})</h4>
        <button className="button ml-auto mb-0" onClick={handleAddJobBtnClick}>
          <FontAwesomeIcon icon={faPlus} className="mr-3" />
          Add New Job
        </button>
      </div>
      <div
        className="actions"
        style={{ marginRight: results.length > 5 ? "3.2rem" : "1.6rem" }}
      >
        <div className="form-icon-group form-sm">
          <input
            type="text"
            name="search"
            className="mb-0"
            onChange={handleSearchChange}
          />
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
        <CheckBoxSelect
          className="ml-auto mr-1 ml-1"
          options={getStatusFilterOptions()}
          value={status}
          onChange={setStatus}
          placeholder={`Status ${
            status?.length > 0 ? ` (${status.length})` : ""
          }`}
        />
        <Select
          placeholder="Sort"
          options={getSortOptions()}
          value={sort}
          onChange={setSort}
        />
      </div>
      <div className="content" onScroll={handleOnJobListScroll}>
        {results.map((job) => (
          <JobCard
            job={job}
            key={job.id}
            onClick={() => handleSelectJob(job)}
            isSelected={selectedJob?.id === job.id}
          />
        ))}
        {isLoading ? (
          <Loader className="d-flex my-2" />
        ) : (
          results.length < 1 && (
            <div className="no-results">No results match your search.</div>
          )
        )}
      </div>
      <JobModal
        isModalOpened={isJobModalOpened}
        setIsModalOpened={setIsJobModalOpened}
        setForceReloadJobs={setForceReloadJobs}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs,
    selectedJob: state.job,
  };
};

const mapDispatchToProps = {
  loadJobs,
  invalidateJobsState,
  setSelectedJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobList);

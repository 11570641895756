export default class TableAxis {
  index: number;
  startsOn: number;
  endsOn: number;
  reverse: boolean;
  showLabel: boolean;
  prioritizeLabel: boolean;

  constructor(props: any = {}) {
    const {
      index = 0,
      startsOn = 0,
      endsOn,
      reverse = false,
      showLabel = true,
      prioritizeLabel = false,
    } = props;

    this.index = index;
    this.startsOn = startsOn;
    this.endsOn = endsOn;
    this.reverse = reverse;
    this.showLabel = showLabel;
    this.prioritizeLabel = prioritizeLabel;
  }
}

import { useState, useEffect, useRef } from "react";
import { statusLabels } from "../../../libs/helpers/jobStatusHelper";
import { debounce } from "../../../libs/helpers/debounceHelper";

const DEFAULT_PAGE = 1;
const page_size = 8;

const useJobList = ({
  jobs,
  loadJobs,
  selectedJob,
  invalidateJobsState,
  setSelectedJob,
}) => {
  const isMounted = useRef(true);
  const searchRef = useRef(null);
  const scrollRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [sort, setSort] = useState(null);
  const [results, setResults] = useState([]);
  const [isJobModalOpened, setIsJobModalOpened] = useState(false);
  const [forceReloadJobs, setForceReloadJobs] = useState(false);

  useEffect(() => {
    setResults(jobs.results);
  }, [jobs]);

  useEffect(() => {
    if (isComponentMounted()) return;
    const loadedJobsCount = jobs.results.length;
    const recordDiff = page_size * jobs.current - loadedJobsCount;

    if (recordDiff > 0 && loadedJobsCount < jobs.total_count) {
      const params = {
        page_size: recordDiff,
        page: page_size * jobs.current,
      };

      fetchJobs(params, true);
    }
  }, [jobs.total_count]);

  useEffect(() => {
    if (shouldLazyLoadNextPage()) fetchJobs();
  }, [page]);

  useEffect(() => {
    if (!forceReloadJobs) return;
    if (page !== DEFAULT_PAGE) setPage(DEFAULT_PAGE);

    debounce(searchRef, 300, fetchJobs, [{ page: DEFAULT_PAGE }, false, true]);
    setForceReloadJobs(false);
  }, [forceReloadJobs]);

  useEffect(() => {
    if (!isComponentMounted()) setForceReloadJobs(true);
  }, [sort, status, search]);

  useEffect(() => {
    if (!selectedJob) selectFirstJobFromList();
  }, [isLoading, selectedJob]);

  useEffect(() => {
    isMounted.current = false;
    return () => invalidateJobsState();
  }, []);

  const fetchJobs = (
    params = {},
    reflectResultsOnly = false,
    invalidateState = false
  ) => {
    setIsLoading(true);

    if (invalidateState) invalidateJobsState();

    loadJobs({ ...getQueryParams(), ...params }, reflectResultsOnly).finally(
      () => setIsLoading(false)
    );
  };

  const shouldLazyLoadNextPage = () => {
    return isComponentMounted() || (hasMoreToLoad() && hasPageChanged());
  };

  const selectFirstJobFromList = () => {
    const job = jobs.results?.[0];

    if (job) setSelectedJob(job);
  };

  const getSortOptions = () => [
    { value: "name", label: "Job name A-Z" },
    { value: "status", label: "Status" },
    { value: "-created_at", label: "Newest first" },
    { value: "created_at", label: "Oldest first" },
    { value: "document_count", label: "Number of files" },
  ];

  const getStatusFilterOptions = () => {
    return Object.entries(statusLabels).map(([value, label]) => ({
      label,
      value,
    }));
  };

  const getQueryParams = () => {
    const params = {};

    if (search) params.search = search;
    if (status) params.status = status.map((item) => item.value);
    if (sort) params.ordering = sort.value;

    return { ...params, page, page_size };
  };

  const resolveBottomEdgeScroll = (element) => {
    if (isLoading || !hasMoreToLoad()) return;

    const { scrollHeight, scrollTop, clientHeight } = element;
    const currentPosition = scrollHeight - scrollTop - 10;

    if (currentPosition <= clientHeight) {
      debounce(scrollRef, 30, setPage, [page + 1]);
    }
  };

  const hasPageChanged = () => jobs.current !== page;

  const hasMoreToLoad = () => jobs.next;

  const isComponentMounted = () => isMounted.current;

  return {
    jobs,
    results,
    isLoading,
    selectedJob,
    setSelectedJob,
    setSearch,
    status,
    setStatus,
    getStatusFilterOptions,
    sort,
    setSort,
    getSortOptions,
    resolveBottomEdgeScroll,
    isJobModalOpened,
    setIsJobModalOpened,
    setForceReloadJobs,
  };
};

export default useJobList;

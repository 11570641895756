import React from "react";
import "./Badge.css";

const Badge = ({ type, children, className = "", ...props }) => {
  return (
    <span className={`${className} badge badge-${type}`} {...props}>
      {children}
    </span>
  );
};

export default Badge;

import { SEGMENT_TYPE_PHRASE, SEGMENT_TYPE_TABLE_GRID } from "../../types";
import Document from "../Document";

class Tearsheet extends Document {
  getSectionConfig(): any {
    return {
      "General Info": {
        date: {
          label: "Tear Sheet Date",
          type: SEGMENT_TYPE_PHRASE,
        },
      },
      "Monthly Performance (Net)": {
        items: {
          type: SEGMENT_TYPE_TABLE_GRID,
        },
      },
      "Fund Exposure-SECTOR": {
        items: {
          type: SEGMENT_TYPE_TABLE_GRID,
        },
      },
      "Performance Attribution-SECTOR": {
        items: {
          type: SEGMENT_TYPE_TABLE_GRID,
        },
      },
      "Liquidity Exposure": {
        items: {
          type: SEGMENT_TYPE_TABLE_GRID,
        },
      },
      Performance: {
        items: {
          type: SEGMENT_TYPE_TABLE_GRID,
        },
      },
    };
  }
}

export default Tearsheet;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Badge from "../../components/badge/Badge";

const STATUS_FLAGGED = "f";
const STATUS_PROCESSING = "i";
const STATUS_PENDING = "p";
const STATUS_ERROR = "e";
const STATUS_COMPLETE = "c";

export const statusLabels = {
  [STATUS_COMPLETE]: "Complete",
  [STATUS_ERROR]: "Error",
  [STATUS_FLAGGED]: "Flagged",
  [STATUS_PROCESSING]: "Processing",
  [STATUS_PENDING]: "Pending",
};

const stateBadgeTypes = {
  [STATUS_COMPLETE]: "success",
  [STATUS_ERROR]: "danger",
  [STATUS_FLAGGED]: "danger-light",
  [STATUS_PROCESSING]: "warning",
  [STATUS_PENDING]: "warning",
};

export const getStatusBadgeByKey = (key, showIconForComplete = true) => {
  return showIconForComplete && key === STATUS_COMPLETE ? (
    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
  ) : (
    <Badge type={getBadgeTypeByKey(key)}>{getStatusLabelByKey(key)}</Badge>
  );
};

export const getBadgeTypeByKey = (key) => {
  return stateBadgeTypes?.[key];
};

export const getStatusLabelByKey = (key) => {
  return statusLabels?.[key];
};

export const isComplete = (status) => status === STATUS_COMPLETE;

export const isProcessing = (status) => status === STATUS_PROCESSING;

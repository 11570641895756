import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loader = ({ size = "xl", className = "d-flex h-100", ...props }) => {
  return (
    <div className={className} {...props}>
      <FontAwesomeIcon className="m-auto" icon={faSpinner} size={size} spin />
    </div>
  );
};

export default Loader;

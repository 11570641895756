const initialState = {
  auth: {
    date: null,
    email: null,
    exp: null,
    iat: null,
    isAuthentificated: false,
    jti: null,
    token_type: null,
    user: null,
    user_id: null,
    gapiAuth: null,
  },
  jobs: {
    current: 1,
    next: null,
    previous: null,
    results: [],
    total_count: 0,
  },
  job: null,
  documents: {
    current: 1,
    next: null,
    previous: null,
    results: [],
    total_count: 0,
  },
  document: null,
};

export default initialState;

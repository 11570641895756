export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const LOAD_JOBS_SUCCESS = "LOAD_JOBS_SUCCESS";
export const LOAD_JOB_DOCUMENTS_SUCCESS = "LOAD_JOB_DOCUMENTS_SUCCESS";
export const LOAD_SELECTED_JOB_SUCCESS = "LOAD_SELECTED_JOB_SUCCESS";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const SET_SELECTED_JOB = "SET_SELECTED_JOB";
export const INVALIDATE_JOBS_STATE = "INVALIDATE_JOBS_STATE";
export const INVALIDATE_JOBS_DOCUMENTS = "INVALIDATE_JOBS_DOCUMENTS";

// Document Action Types

export const LOAD_DOCUMENTS_SUCCESS = "LOAD_DOCUMENTS_SUCCESS";
export const INVALIDATE_DOCUMENTS_STATE = "INVALIDATE_DOCUMENTS_STATE";

export const LOAD_DOCUMENT_SUCCESS = "LOAD_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const INVALIDATE_DOCUMENT_STATE = "INVALIDATE_DOCUMENT_STATE";

import Badge from "../../components/badge/Badge";
import {
  TYPE_TEARSHEET,
  TYPE_INVOICE,
  TYPE_MEDICAL_FORM,
  TYPE_I_9_FORM,
  TYPE_W_2_FORM,
  TYPE_1099_NEC_FORM,
} from "../modules/parser/types";

export const typeLabels = {
  [TYPE_TEARSHEET]: "Tearsheet",
  [TYPE_INVOICE]: "Invoice",
  [TYPE_MEDICAL_FORM]: "Medical ROI",
  [TYPE_I_9_FORM]: "I-9 Form",
  [TYPE_W_2_FORM]: "W-2 Form",
  [TYPE_1099_NEC_FORM]: "1099 NEC Form",
};

export const getTypeLabelByKey = (key) => {
  return typeLabels?.[key] ?? "Unknown";
};

export const getTypeBadgeByKey = (key) => {
  return <Badge type="bland">{getTypeLabelByKey(key)}</Badge>;
};

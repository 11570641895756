class BoundingBox {
  top: number;
  right: number;
  bottom: number;
  left: number;

  constructor({ top, right, bottom, left }: any) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
  }

  get width() {
    return this.right - this.left;
  }

  get height() {
    return this.bottom - this.top;
  }

  get horizontalMidpoint() {
    return (this.right + this.left) / 2;
  }

  get verticalMidpoint() {
    return (this.top + this.bottom) / 2;
  }
}

export default BoundingBox;

import { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";
import styles from "./tooltip.module.css";

const Tooltip = ({ children, content, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      align="center"
      positions={["top", "bottom", "right", "left"]}
      reposition={true}
      padding={2}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="var(--neutral-gray)"
          arrowSize={8}
          className="arrow-container"
          arrowClassName="arrow"
        >
          <div className={styles.content}>{content}</div>
        </ArrowContainer>
      )}
      {...props}
    >
      <span
        className={styles.control}
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
      >
        {children}
      </span>
    </Popover>
  );
};

export default Tooltip;

import Invoice from "./Invoice";
import { mergeNestedObjects } from "../../utils";
import { FIELD_TYPE_KEY, FIELD_TYPE_PHRASE } from "../../types";

class PowerConsultITInvoice extends Invoice {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const {
      issuer,
      description,
      issueDate,
      invoiceNumber,
      accountName,
      total,
    } = this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        issuer: {
          field: issuer,
        },
        issueDate: {
          field: issueDate,
        },
        number: {
          field: invoiceNumber,
        },
        customer: {
          field: accountName,
        },
      },
      "Itemized Details": {
        items: {
          edges: {
            tEdge: { include: false, field: description },
            bEdge: { include: false, field: total },
          },
          valueIndex: 1,
          dropColumnIndexes: [1, 2],
        },
      },
      "Amount Due": {
        total: {
          field: total,
        },
      },
    });
  }

  getReferentFields(): any {
    const map: any = {
      issuer: {
        needle: "Power Consult IT",
        type: FIELD_TYPE_PHRASE,
      },
      description: {
        needle: "Description",
        type: FIELD_TYPE_PHRASE,
      },
      issueDate: {
        needle: "Invoice Date:",
        type: FIELD_TYPE_KEY,
      },
      dueDate: {
        needle: "Due Date:",
        type: FIELD_TYPE_KEY,
      },
      invoiceNumber: {
        needle: "Invoice #:",
        type: FIELD_TYPE_KEY,
      },
      accountName: {
        needle: "Bill To:",
        type: FIELD_TYPE_KEY,
      },
      total: {
        type: FIELD_TYPE_KEY,
        needle: "Total",
      },
    };

    return super.getReferentFields(map);
  }
}

export default PowerConsultITInvoice;

const {
  ENVIRONMENT,
  API_URL,
  BACKEND_URL,
  INFO_EMAIL,
  CLIENT_ID,
  REDIRECT_SITE,
  AUTH_URL
} = window.__env__;

export const env = {
  env: ENVIRONMENT,
  apiUrl: API_URL,
  backendUrl: BACKEND_URL,
  infoEmail: INFO_EMAIL,
  clientID: CLIENT_ID,
  redirectSite: REDIRECT_SITE,
  auth_url: AUTH_URL,
};

import React from "react";
import { connect } from "react-redux";
import {
  loadDocument,
  updateDocument,
  invalidateDocumentState,
} from "../../../libs/redux/actions/documentActions";
import { download } from "../../../libs/redux/actions/fileActions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStatusBadgeByKey } from "../../../libs/helpers/documentStatusHelper";
import { faChevronLeft, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { getTypeBadgeByKey } from "../../../libs/helpers/documentTypeHelper";
import {
  getFirstFileByType,
  FILE_TYPE_SOURCE,
} from "../../../libs/helpers/fileTypeHelper";
import { getFormattedFileName } from "../../../libs/helpers/baseHelper";
import ToggleSwitch from "../../../components/toogle-switch/ToggleSwitch";
import Dropdown from "../../../components/dropdown/Dropdown";
import Loader from "../../../components/Loader";
import ResizablePanel from "../../../components/resizable-panel/ResizablePanel";
import ReviewPanel from "@makai/review-panel";
import FileViewer from "@makai/file-viewer";
import useDocumentReview from "./hooks/useDocumentReview";
import "@makai/file-viewer/dist/index.css";
import "@makai/review-panel/dist/index.css";
import "./DocumentReview.css";

const DocumentReview = (props) => {
  const {
    document,
    sections,
    setSections,
    isLoading,
    isSaving,
    setForm,
    selectedField,
    setSelectedField,
    getDocumentImages,
    getFormattedLastUpdatedLabel,
    download,
    downloadExcel,
    saveFileDetails,
    isContinuousReviewMode,
    setIsContinuousReviewMode,
    highlightBox,
  } = useDocumentReview(props);

  const handleFieldChange = ({ id, value }, sectionIndex) => {
    setForm((prev) => {
      return {
        ...prev,
        fields: {
          ...prev.fields,
          ...{ [id]: value },
        },
      };
    });

    sections.map((section, index) =>
      index === sectionIndex
        ? {
            ...section,
            fields: section.fields.map((field) => {
              if (field.id === id) {
                field.value = value;
              }

              return field;
            }),
          }
        : section
    );

    setSections((prev) => {
      return prev.map((section, index) =>
        index === sectionIndex
          ? {
              ...section,
              fields: section.fields.map((field) => {
                if (field.id === id) {
                  field.value = value;
                }

                return field;
              }),
            }
          : section
      );
    });
  };

  const handleSaveProgress = (e) => {
    e.preventDefault();
    saveFileDetails();
  };

  const handleCompleteReview = (e) => {
    e.preventDefault();
    saveFileDetails(true);
  };

  const getHamburgerMenuItems = () => {
    const options = [
      {
        content: "Download PDF",
        onClick: () => handleDownloadPDF(),
      },
    ];

    options.unshift({
      content: "Download Excel",
      onClick: downloadExcel,
    });

    return options;
  };

  const handleDownloadPDF = () => {
    const sourceFile = getFirstFileByType(document.files, FILE_TYPE_SOURCE);

    if (sourceFile) {
      const { id, name } = sourceFile;
      const fileName = getFormattedFileName(name, "pdf");

      download(id, { name: fileName });
    }
  };

  return (
    <div className="document-review">
      <div className="header">
        <span className="go-back">
          <Link to="/jobs">
            <FontAwesomeIcon icon={faChevronLeft} size="sm" className="mr-1" />
            Go Back
          </Link>
        </span>
        <div className="content d-flex align-items-center mt-1">
          <div className="details mr-auto">
            {document && (
              <>
                <div className="d-flex align-items-center title mr-3">
                  <div className="text-truncate" title={document.name}>
                    {document.name}
                  </div>
                  <div className="ml-3">
                    {getStatusBadgeByKey(document?.status)}
                  </div>
                </div>
                <small className="text-secondary">
                  {getFormattedLastUpdatedLabel()}
                  <span className="ml-1">
                    {getTypeBadgeByKey(document.type)}
                  </span>
                </small>
              </>
            )}
          </div>
          <ToggleSwitch
            label="Continuous Review Mode"
            checked={isContinuousReviewMode}
            setChecked={setIsContinuousReviewMode}
            disabled={isSaving || isLoading}
          />
          <button
            type="submit"
            className="button button-outline mr-1"
            onClick={handleSaveProgress}
            disabled={isSaving || isLoading}
          >
            Save Progress
          </button>
          <button
            type="submit"
            className="button mr-1"
            onClick={handleCompleteReview}
            disabled={isSaving || isLoading}
          >
            Complete Review
          </button>
          <Dropdown
            isDisabled={isLoading}
            items={getHamburgerMenuItems()}
            padding={8}
          >
            <button
              className="button button-light button-icon"
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
          </Dropdown>
        </div>
      </div>
      <div className="content">
        <ResizablePanel className="left-panel">
          {isLoading ? (
            <Loader />
          ) : (
            <ReviewPanel
              sections={sections}
              pageImages={getDocumentImages()}
              onFieldChange={handleFieldChange}
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
          )}
        </ResizablePanel>
        <div className="right-panel">
          {isLoading ? (
            <Loader />
          ) : (
            <FileViewer
              pageImages={getDocumentImages()}
              highlightBox={highlightBox}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    document: state.document,
    selectedJob: state.job,
  };
};

const mapDispatchToProps = {
  download,
  loadDocument,
  updateDocument,
  invalidateDocumentState,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentReview);

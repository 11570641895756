export const PRIMARY_COLOR = "#0D5BC4";
export const SECONDARY_COLOR = "#FFFFFF";
export const ICON_QUESTION = "question";

export const getDefaultOptions = () => {
  return {
    heightAuto: false,
    confirmButtonColor: PRIMARY_COLOR,
    cancelButtonColor: SECONDARY_COLOR,
  };
};

export const getConfirmDialogOptions = () => {
  return {
    ...getDefaultOptions(),
    icon: ICON_QUESTION,
    iconColor: PRIMARY_COLOR,
    showCancelButton: true,
    confirmButtonText: "Yes",
  };
};

import { toast } from "react-toastify";

const SUCCESS_CODES = Array.from({ length: 100 }, (_, i) => 200 + i);
const SERVER_ERROR_CODES = Array.from({ length: 100 }, (_, i) => 500 + i);

export async function handleResponse(response) {
  if (SUCCESS_CODES.includes(response.status)) {
    return response.data;
  }

  return response;
}

export function handleError(error) {
  const status = error?.response?.status;
  if (SERVER_ERROR_CODES.includes(status)) {
    toast.error("Something went wrong !");
  }

  throw error;
}

export const paramsSerializer = (params) => {
  if (!params) return params;

  return Object.keys(params)
    .map((key) => {
      if (!Array.isArray(params[key])) return `${key}=${params[key]}`;
      return params[key].map((i) => `${key}=${i}`).join("&");
    })
    .join("&");
};

import {
  SEGMENT_TYPE_KEY_VALUE_SET,
  SEGMENT_TYPE_PHRASE,
  SEGMENT_TYPE_TABLE_LIST,
} from "../../types";
import Document from "../Document";

class Invoice extends Document {
  getSectionConfig(): any {
    return {
      "General Info": {
        issuer: {
          label: "Invoice Issuer",
          type: SEGMENT_TYPE_PHRASE,
          field: null,
        },
        issueDate: {
          label: "Invoice Date",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
          input_type: "date",
        },
        number: {
          label: "Invoice Number",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
        },
        dueDate: {
          label: "Due Date",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
          input_type: "date",
        },
        customer: {
          label: "Account Name / Bill To",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
        },
      },
      "Itemized Details": {
        items: {
          type: SEGMENT_TYPE_TABLE_LIST,
        },
      },
      "Amount Due": {
        subtotal: {
          label: "Subtotal",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
        },
        tax: {
          label: "Tax",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
        },
        total: {
          label: "Total / Grand Total / Balance Due",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: null,
        },
      },
    };
  }

  getExcelConfig(): any {
    return {
      "General Info": {
        colOptions: [{ wch: 20 }, { wch: 50 }],
        cellOptions: [
          {
            font: { bold: true },
          },
        ],
      },
      "Itemized Details": {
        colOptions: [{ wch: 50 }, { wch: 20 }],
        cellOptions: [{}, { alignment: { horizontal: "right" } }],
        headerRow: [
          { v: "Item / Description", s: { font: { bold: true } } },
          {
            v: "Price",
            s: { font: { bold: true }, alignment: { horizontal: "right" } },
          },
        ],
      },
      "Amount Due": {
        colOptions: [{ wch: 30 }, { wch: 20 }],
        cellOptions: [
          { font: { bold: true } },
          { alignment: { horizontal: "right" } },
        ],
      },
    };
  }
}

export default Invoice;

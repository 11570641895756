import React from "react";
import "./NotificationItem.css";

const NotificationItem = ({ title, timestamp, isFlagged = false }) => {
  return (
    <div className="notification-item d-flex align-items-center">
      <div className="content mr-auto">
        <div className="title">{title}</div>
        <small className="timestamp">{timestamp}</small>
      </div>
      {isFlagged && <span className="dot ml-2"></span>}
    </div>
  );
};

export default NotificationItem;

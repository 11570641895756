import { defaultStyles } from "../../libs/helpers/reactSelectHelper";
import Select, { components } from "react-select";

const CheckBoxSelect = ({ className, ...props }) => {
  return (
    <Select
      className={`react-select-control ${className}`}
      styles={defaultStyles}
      placeholder="Filter"
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      isClearable={false}
      components={{
        Option,
        MenuList,
      }}
      {...props}
    />
  );
};

const MenuList = (props) => {
  return (
    <div>
      <components.MenuList {...props}>
        {props.children}
        <div className="react-select-clear" onClick={() => props.clearValue()}>
          Clear all
        </div>
      </components.MenuList>
    </div>
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check react-select-item">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export default CheckBoxSelect;

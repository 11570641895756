import React from "react";
import { NavLink } from "react-router-dom";
import "./NavbarMenu.css";

const NavbarMenu = ({ items }) => {
  return (
    <nav className="nav-menu">
      {items.map((item, index) => (
        <NavLink
          to={item.to}
          className={({ isActive }) => `nav-item ${isActive ? "active" : ""}`}
          key={index}
        >
          {item.content}
        </NavLink>
      ))}
    </nav>
  );
};

export default NavbarMenu;

import {
  FIELD_TYPE_KEY,
  FIELD_TYPE_SELECTED_ELEMENT,
  SEGMENT_TYPE_KEY_VALUE_SET,
  SEGMENT_TYPE_PHRASE,
} from "../types";
import Document from "./Document";

class I9Form extends Document {
  getSectionConfig(): any {
    const {
      lastName,
      firstName,
      middleInitial,
      address,
      aptNo,
      city,
      state,
      zip,
      dob,
      socialSecNo,
      employeeEmail,
      employeePhone,
      attestOpt1,
      attestOpt2,
      attestOpt3,
      attestOpt4,
      preparerOpt1,
      preparerOpt2,
    } = this.getReferentFields();

    return {
      "Employee Info and Attestation": {
        lastName: {
          label: "Last Name (Family Name)",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: lastName,
        },
        firstName: {
          label: "First Name (Given Name)",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: firstName,
        },
        middleInitial: {
          label: "Middle Initial",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: middleInitial,
        },
        address: {
          label: "Address (Street Number and Name)",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: address,
        },
        aptNo: {
          label: "Apt. Number",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: aptNo,
        },
        city: {
          label: "City or Town",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: city,
        },
        state: {
          label: "State",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: state,
        },
        zip: {
          label: "Zip Code",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: zip,
        },
        dob: {
          label: "Date of Birth (mm/dd/yyyy)",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: dob,
          input_type: "date",
        },
        socialSecNo: {
          label: "U.S. Social Security Number ",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: socialSecNo,
        },
        employeeEmail: {
          label: "Employee's Email Address",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: employeeEmail,
        },
        employeePhone: {
          label: "Employee's Telephone Number",
          type: SEGMENT_TYPE_KEY_VALUE_SET,
          field: employeePhone,
        },
        attestation: {
          label: "Attestation",
          type: SEGMENT_TYPE_PHRASE,
          field: attestOpt1 ?? attestOpt2 ?? attestOpt3 ?? attestOpt4,
        },
        preparer: {
          label: "Preparer and/or Translator Certification",
          type: SEGMENT_TYPE_PHRASE,
          field: preparerOpt1 ?? preparerOpt2,
        },
      },
    };
  }

  public getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getReferentFields(): any {
    const map: any = {
      lastName: {
        needle: "Last Name (Family Name)",
        type: FIELD_TYPE_KEY,
      },
      firstName: {
        needle: "First Name (Given Name)",
        type: FIELD_TYPE_KEY,
      },
      middleInitial: {
        needle: "Middle Initial",
        type: FIELD_TYPE_KEY,
      },
      address: {
        needle: "Address (Street Number and Name)",
        type: FIELD_TYPE_KEY,
      },
      aptNo: {
        needle: "Apt. Number",
        type: FIELD_TYPE_KEY,
      },
      city: {
        needle: "City or Town",
        type: FIELD_TYPE_KEY,
      },
      state: {
        needle: "State",
        type: FIELD_TYPE_KEY,
      },
      zip: {
        needle: "ZIP Code",
        type: FIELD_TYPE_KEY,
      },
      dob: {
        needle: "Date of Birth (mm/dd/yyyy)",
        type: FIELD_TYPE_KEY,
      },
      socialSecNo: {
        needle: "U.S. Social Security Number",
        type: FIELD_TYPE_KEY,
      },
      employeeEmail: {
        needle: "Employee's E-mail Address",
        type: FIELD_TYPE_KEY,
      },
      employeePhone: {
        needle: "Employee's Telephone Number",
        type: FIELD_TYPE_KEY,
      },
      attestOpt1: {
        needle: "1. A citizen of the United States",
        type: FIELD_TYPE_SELECTED_ELEMENT,
      },
      attestOpt2: {
        needle:
          "2. A noncitizen national of the United States (See instructions)",
        type: FIELD_TYPE_SELECTED_ELEMENT,
      },
      attestOpt3: {
        needle:
          "3. A lawful permanent resident (Alien Registration Number/USCIS Number):",
        type: FIELD_TYPE_SELECTED_ELEMENT,
      },
      attestOpt4: {
        needle:
          "4. An alien authorized to work until (expiration date, if applicable, mm/dd/yyyy):",
        type: FIELD_TYPE_SELECTED_ELEMENT,
      },
      preparerOpt1: {
        needle: "I did not use a preparer or translator.",
        type: FIELD_TYPE_SELECTED_ELEMENT,
      },
      preparerOpt2: {
        needle:
          "A preparer(s) and/or translator(s) assisted the employee in completing Section 1.",
        type: FIELD_TYPE_SELECTED_ELEMENT,
      },
    };

    return super.getReferentFields(map);
  }

  getExcelConfig(): any {
    return {
      "Employee Info and Attestation": {
        colOptions: [{ wch: 50 }, { wch: 50 }],
        cellOptions: [
          {
            font: { bold: true },
          },
        ],
      },
    };
  }
}

export default I9Form;

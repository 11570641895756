import React from "react";
import "./Notifications.css";
import Dropdown from "../dropdown/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import NotificationItem from "./NotificationItem";

const Notifications = () => {
  const getNotificationDropdownItems = () => {
    return notifications.map((item, index) => ({
      content: (
        <NotificationItem
          key={index}
          title={item.title}
          timestamp={item.timestamp}
          isFlagged={item.isFlagged}
        />
      ),
    }));
  };

  return (
    <div className="notifications">
      <Dropdown items={getNotificationDropdownItems()} padding={16}>
        <button className="button button-header d-flex align-items-center">
          <FontAwesomeIcon icon={faBell} size="lg" />
          <span className="dot"></span>
        </button>
      </Dropdown>
    </div>
  );
};

const notifications = [
  {
    title: "Test Job 234 extraction ERROR",
    timestamp: "2 hours ago",
    isFlagged: true,
  },
  {
    title: "Test Job A1234 finished processing",
    timestamp: "3 days ago",
    isFlagged: true,
  },
  {
    title: "Job 0238746 — review complete",
    timestamp: "Sep 4, 2022  7:24am",
    isFlagged: false,
  },
];

export default Notifications;

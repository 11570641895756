import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDocuments } from "../../../../libs/api/documentApi";
import { STATUS_NEEDS_REVIEW } from "../../../../libs/helpers/documentStatusHelper";
import {
  getLocalReviewMode,
  setLocalReviewMode,
  REVIEW_MODE_DEFAULT,
  REVIEW_MODE_CONTINUOUS,
} from "../../../../libs/helpers/reviewModeHelper";
import {
  getConfirmDialogOptions,
  getDefaultOptions,
} from "../../../../libs/helpers/swalHelper";
import Swal from "sweetalert2";

const useReviewMode = ({ document }) => {
  const navigate = useNavigate();
  const [isContinuousReviewMode, setIsContinuousReviewMode] = useState(
    getLocalReviewMode() === REVIEW_MODE_CONTINUOUS
  );

  useEffect(() => {
    const mode = isContinuousReviewMode
      ? REVIEW_MODE_CONTINUOUS
      : REVIEW_MODE_DEFAULT;

    setLocalReviewMode(mode);
  }, [isContinuousReviewMode]);

  const afterCompletedReview = () => {
    isContinuousReviewMode
      ? handleContinuousReviewMode()
      : handleDefaultReviewMode();
  };

  const handleContinuousReviewMode = async () => {
    const { nextFile, remainingCount } = await getNextForReview();

    if (remainingCount < 1) {
      handleDefaultReviewMode();
      return;
    }

    Swal.fire({
      ...getConfirmDialogOptions(),
      confirmButtonText: "Review Next",
      cancelButtonText: "Go Back",
      showCloseButton: true,
      title: "File review is completed successfully !",
      text: `There ${
        remainingCount > 1
          ? `are ${remainingCount} files`
          : `is ${remainingCount} file`
      } left in this job for review`,
    }).then(({ isConfirmed, dismiss }) => {
      if (isConfirmed) {
        navigate(`/files/${nextFile.id}/review`, { replace: true });
      } else if (dismiss === "cancel") {
        navigate(-1) ?? navigate("/jobs");
      }
    });
  };

  const handleDefaultReviewMode = () => {
    Swal.fire({
      ...getDefaultOptions(),
      icon: "success",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "Go Back",
      timer: 10000,
      timerProgressBar: true,
      title: `File review is completed successfully !`,
      willClose: () => navigate(-1) ?? navigate("/jobs"),
    });
  };

  const getNextForReview = () => {
    const params = {
      batch_id: document.batch.id,
      page: 1,
      page_size: 1,
      status: STATUS_NEEDS_REVIEW,
    };

    return getDocuments(params).then((payload) => ({
      nextFile: payload?.results[0] ?? null,
      remainingCount: payload?.total_count ?? 0,
    }));
  };

  return {
    isContinuousReviewMode,
    setIsContinuousReviewMode,
    afterCompletedReview,
  };
};

export default useReviewMode;

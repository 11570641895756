import BoundingBox from "../core/BoundingBox";
import Field from "../core/Field";
import MissingField from "../core/MissingField";
import Segment from "./Segment";

class Table extends Segment {
  constructor(
    name: string = null,
    content: Field[] = [],
    missingFields: MissingField[] = [],
    dates: Field[] = []
  ) {
    super(name, content, missingFields, dates);
  }

  get columns(): Field[][] {
    const columns: any = {};
    let fields = this.content.sort(
      (a, b) => b.boundingBox.width - a.boundingBox.width
    );

    for (let i = fields.length - 1; i >= 0; i--) {
      const field = fields[i];

      if (!field) {
        continue;
      }

      const { left, horizontalMidpoint } = field.boundingBox;

      const verticalLine = new BoundingBox({
        top: 0,
        bottom: 1,
        left: horizontalMidpoint,
        right: horizontalMidpoint,
      });

      const overlappingFields = fields.filter((i) =>
        i.isOverlappingBoundingBox(verticalLine)
      );

      fields = fields.filter((i) => !i.isOverlappingBoundingBox(verticalLine));

      columns[left] = overlappingFields.sort(
        (a, b) => a.boundingBox.top - b.boundingBox.top
      );
    }

    return Object.values(
      Object.keys(columns)
        .sort()
        .reduce((r: any, k) => {
          r[k] = columns[k];
          return r;
        }, {})
    );
  }

  get rows(): Field[][] {
    const rows: any = {};
    let fields = this.content.sort(
      (a, b) => b.boundingBox.height - a.boundingBox.height
    );

    for (let i = fields.length - 1; i >= 0; i--) {
      const field = fields[i];

      if (!field) {
        continue;
      }

      const { top, verticalMidpoint } = field.boundingBox;

      const horizontalLine = new BoundingBox({
        top: verticalMidpoint,
        bottom: verticalMidpoint,
        left: 0,
        right: 1,
      });

      const overlappingFields = fields.filter((i) =>
        i.isOverlappingBoundingBox(horizontalLine)
      );

      fields = fields.filter(
        (i) => !i.isOverlappingBoundingBox(horizontalLine)
      );

      rows[top] = overlappingFields.sort(
        (a, b) => a.boundingBox.left - b.boundingBox.left
      );
    }

    return Object.values(
      Object.keys(rows)
        .sort()
        .reduce((r: any, k) => {
          r[k] = rows[k];
          return r;
        }, {})
    );
  }
}

export default Table;

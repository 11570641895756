import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
import styles from "./resizable-panel.module.css";

const ResizablePanel = ({ children, className = "", ...props }) => {
  const panelRef = useRef(null);
  const splitterRef = useRef(null);

  const previousX = useRef(null);
  const initialPanelRect = useRef(null);

  const handleMouseDown = (e) => {
    previousX.current = e.clientX;
    initialPanelRect.current = panelRef.current.getBoundingClientRect();

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = (e) => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    panelRef.current.style.width =
      initialPanelRect.current.width - previousX.current + e.x + "px";
  };

  return (
    <div
      ref={panelRef}
      className={`${styles.resizablePanel} ${className}`}
      {...props}
    >
      {children}
      <div
        ref={splitterRef}
        className={styles.splitter}
        onMouseDown={handleMouseDown}
      >
        <FontAwesomeIcon icon={faGripLinesVertical} />
      </div>
    </div>
  );
};

export default ResizablePanel;

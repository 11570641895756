import Field from "../../core/Field";
import MissingField from "../../core/MissingField";
import Table from "../Table";
import TableAxis from "./TableAxis";

export default class TableGrid extends Table {
  columnAxis: TableAxis;
  rowAxis: TableAxis;
  showMissingFields: boolean;
  maxInterDist: number;

  constructor(
    name: string,
    fields: Field[],
    columnAxis: TableAxis,
    rowAxis: TableAxis,
    showMissingFields: boolean = true,
    missingFields: MissingField[] = [],
    maxInterDist: number = 0.0025
  ) {
    super(name, fields, missingFields);

    this.columnAxis = columnAxis;
    this.rowAxis = rowAxis;
    this.showMissingFields = showMissingFields;
    this.maxInterDist = maxInterDist;
  }

  get fields(): any[] {
    const result = [];

    const column = this.columns[this.columnAxis.index];
    const row = this.rows[this.rowAxis.index];

    if (!column || !row) {
      return [];
    }

    const yEndsOn = this.rowAxis.endsOn ?? row.length;
    const xEndsOn = this.columnAxis.endsOn ?? column.length;

    if (this.columnAxis.reverse) {
      column.reverse();
    }

    if (this.rowAxis.reverse) {
      row.reverse();
    }

    for (let x = this.columnAxis.startsOn; x < xEndsOn; x++) {
      const colField = column[x];

      if (!colField) continue;

      for (let y = this.rowAxis.startsOn; y < yEndsOn; y++) {
        const rowField = row[y];
        const labelArray = [];

        if (!rowField) continue;

        // If the x, y fields are the same field skip loop
        if (rowField.id === colField.id) continue;

        let field: Field | MissingField = this.getIntersectionField(
          rowField,
          colField,
          this.maxInterDist
        );

        if (!field) {
          if (!this.showMissingFields) {
            continue;
          }

          const key = `${rowField.id}-${colField.id}`;

          field =
            this.missingFields.find((x) => x.key === key) ??
            new MissingField({ key });
        }

        if (this.columnAxis.showLabel) {
          labelArray.push(colField.extractedValue);
        }

        if (this.rowAxis.showLabel) {
          this.rowAxis.prioritizeLabel
            ? labelArray.unshift(rowField.extractedValue)
            : labelArray.push(rowField.extractedValue);
        }

        field.label = labelArray.join(" ");

        result.push(field);
      }
    }

    return result;
  }
}

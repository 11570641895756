import * as types from "./actionTypes";
import { gapi } from "gapi-script";
import { revoke } from "../../api/authApi";
import { jwtDecode } from "jwt-decode";
import authService from "../../services/authService";
import { env } from "../../../environments";

export function loginSuccess() {
  const token = authService.getLocalAccessToken();
  const auth = {
    ...jwtDecode(token),
    gapiAuth: gapi.auth2?.getAuthInstance() ?? null,
    isAuthentificated: true,
  };

  return { type: types.LOGIN_SUCCESS, auth };
}

export function logoutSuccess() {
  window.location.replace(`${env.auth_url}logout/`)
  return { type: types.LOGOUT_SUCCESS };
}

export function logout(token) {
  return function (dispatch) {
    return revoke(token).finally(() => {
      authService.removeAuthData();
      dispatch(logoutSuccess());
    });
  };
}

export function setIsAuthentificated() {
  return function (dispatch) {
    if (authService.isAuthentificated()) {
      dispatch(loginSuccess());
    } else {
      dispatch(logoutSuccess());
    }
  };
}

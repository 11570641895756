export default class MissingField {
  id: string;
  key: string;
  value: string;
  private _label: string;

  constructor({ key, value = null }: any) {
    this.id = key;
    this.key = key;
    this.value = value;
  }

  get label() {
    return this._label;
  }

  set label(value) {
    this._label = value;
  }
}

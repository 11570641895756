import * as types from "./actionTypes";
import * as documentApi from "../../api/documentApi";

export function loadDocumentsSuccess(documents) {
  return { type: types.LOAD_DOCUMENTS_SUCCESS, documents };
}

export function loadDocumentSuccess(document) {
  return { type: types.LOAD_DOCUMENT_SUCCESS, document };
}

export function updateDocumentSuccess(document) {
  return { type: types.UPDATE_DOCUMENT_SUCCESS, document };
}

export function deleteDocumentSuccess(document) {
  return { type: types.DELETE_DOCUMENT_SUCCESS, document };
}

export function invalidateDocumentsState() {
  return { type: types.INVALIDATE_DOCUMENTS_STATE };
}

export function invalidateDocumentState() {
  return { type: types.INVALIDATE_DOCUMENT_STATE };
}

export function loadDocuments(params) {
  return function (dispatch) {
    return documentApi
      .getDocuments(params)
      .then((documents) => {
        dispatch(loadDocumentsSuccess(documents));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadDocument(id, params) {
  return function (dispatch) {
    return documentApi
      .getDocument(id, params)
      .then((document) => {
        dispatch(loadDocumentSuccess(document));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateDocument(id, params) {
  return function (dispatch) {
    return documentApi
      .updateDocument(id, params)
      .then((document) => {
        dispatch(updateDocumentSuccess(document));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteDocument(document, params) {
  return function (dispatch) {
    return documentApi
      .deleteDocument(document.id, params)
      .then(() => {
        dispatch(deleteDocumentSuccess(document));
      })
      .catch((error) => {
        throw error;
      });
  };
}

import Invoice from "./Invoice";
import { mergeNestedObjects } from "../../utils";
import BoundingBox from "../../core/BoundingBox";
import { FIELD_TYPE_BOUNDARY, FIELD_TYPE_KEY } from "../../types";

class HolmConstructionInvoice extends Invoice {
  getSections(): any {
    const config = this.getSectionConfig();
    return super.getSections(config);
  }

  getSectionConfig(): any {
    const {
      issuer,
      issueDate,
      invoiceNumber,
      billTo,
      status,
      totalKey,
      totalVal,
    } = this.getReferentFields();

    return mergeNestedObjects(super.getSectionConfig(), {
      "General Info": {
        issuer: {
          field: issuer,
        },
        issueDate: {
          field: issueDate,
        },
        number: {
          field: invoiceNumber,
        },
        customer: {
          field: billTo,
        },
      },
      "Itemized Details": {
        items: {
          edges: {
            tEdge: {
              include: false,
              field: status,
            },
            bEdge: {
              include: false,
              field: totalVal,
            },
          },
          valueIndex: 2,
        },
      },
      "Amount Due": {
        total: {
          field: totalKey,
        },
      },
    });
  }

  getReferentFields(): any {
    const map: any = {
      issuer: {
        needle: "HOLM CUSTOM CONSTRUCTION",
      },
      issueDate: {
        needle: "Date:",
        type: FIELD_TYPE_KEY,
      },
      invoiceNumber: {
        type: FIELD_TYPE_BOUNDARY,
        boundingBox: new BoundingBox({
          top: 0.07,
          right: 1,
          bottom: 0.1,
          left: 0.65,
        }),
      },
      billTo: {
        needle: "BILL TO",
        type: FIELD_TYPE_KEY,
      },
      totalKey: {
        needle: "Invoice Total:",
        type: FIELD_TYPE_KEY,
      },
      totalVal: {
        needle: "Invoice Total:",
      },
      status: {
        needle: "Status",
      },
    };

    return super.getReferentFields(map);
  }
}

export default HolmConstructionInvoice;

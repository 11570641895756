import { useState, useEffect } from "react";
import useDataProvider from "../../../libs/hooks/useDataProvider";

const useDocumentRepository = ({
  documents,
  loadDocuments,
  invalidateDocumentsState,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => invalidateDocumentsState();
  }, []);

  const fetchDocuments = (params) => {
    setIsLoading(true);

    loadDocuments(params).finally(() => setIsLoading(false));
  };

  const dataProvider = useDataProvider({
    data: documents,
    fetchCallback: fetchDocuments,
  });

  return {
    dataProvider,
    isLoading,
  };
};

export default useDocumentRepository;

import axios from "./axios/authorizedInstance";
import { handleResponse, handleError, paramsSerializer } from "./apiUtils";

const baseUrl = "/v2/batch";

export function getJobs(params) {
  return axios
    .get(`${baseUrl}/`, { params, paramsSerializer })
    .then(handleResponse)
    .catch(handleError);
}

export function getJob(jobId, params) {
  return axios
    .get(`${baseUrl}/${jobId}/`, { params })
    .then(handleResponse)
    .catch(handleError);
}

export function createJob(params) {
  return axios
    .post(`${baseUrl}/`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function deleteJob(jobId, params) {
  return axios
    .delete(`${baseUrl}/${jobId}/`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function getJobDocuments(jobId, params) {
  return axios
    .get(`${baseUrl}/excel/${jobId}/`, { params })
    .then(handleResponse)
    .catch(handleError);
}
